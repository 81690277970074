import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  ids: any[];
};

const initialState: State = {
  ids: [],
};

const multiSelectSlice = createSlice({
  name: 'multiSelect',
  initialState: initialState,
  reducers: {
    setListSelectOptions: (state: State, action: PayloadAction<any>) => {
      state.ids = action.payload;
    },
  },
});

export const { setListSelectOptions } = multiSelectSlice.actions;

export default multiSelectSlice.reducer;
