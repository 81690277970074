import React from 'react';
import { View, Image, Platform } from 'react-native';
import ContentLoader, { Circle } from 'react-content-loader/native';
import { isEmpty } from 'lodash';

type Props = {
  avatarURL: string;
  placeholder: string;
  attributes: Record<string, any>;
  // isCanvas: boolean;
};

const Avatar = ({ avatarURL, placeholder, attributes }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  // const isCanvas = false;
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: attributes?.width,
        height: attributes?.height,
        borderRadius: attributes?.borderRadius,
      }}
    >
      {isLoading ? null : (
        <ContentLoader
          speed={3}
          viewBox="0 0 198 115"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          width="100px"
          height="100px"
          style={{ top: 25 }}
        >
          <Circle cx="100" cy="58" r="50" />
        </ContentLoader>
      )}
      <Image
        source={{
          uri:
            typeof avatarURL !== 'string' || isEmpty(avatarURL)
              ? placeholder
              : avatarURL,
        }}
        onError={() => {
          setIsLoading(false);
        }}
        onLoad={() => {
          setIsLoading(true);
        }}
        style={{
          // ...(!isCanvas && {
          //   opacity: isLoading ? 1 : 0,
          //   display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
          // }),
          width: attributes?.width,
          height: attributes?.height,
          borderRadius: attributes?.borderRadius,
        }}
      />
    </View>
  );
};

export default Avatar;
