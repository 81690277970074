import { useMemo, useState, useEffect } from 'react';
import { Button as RNButton } from 'react-native-paper';
import { ISimpleList } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { Platform } from 'react-native';
import { checkFont, getListItems } from '../func';
import { getActions, getItemListClick, isCheckColor } from '../shared';

export const LIST_ITEM_MODE = {
  LEFT_SECTION: 'leftSection',
  RIGHT_SECTION: 'rightSection',
};

export type LIST_ITEM_MODE = typeof LIST_ITEM_MODE[keyof typeof LIST_ITEM_MODE];

export const POSITION_BUTTON = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export type POSITION_BUTTON =
  typeof POSITION_BUTTON[keyof typeof POSITION_BUTTON];

export const ID_BUTTON = {
  MULTI_SELECT: 'multiSelect',
  MULTI_SELECT_SECOND: 'multiSelectSecond',
};

export type ID_BUTTON = typeof ID_BUTTON[keyof typeof ID_BUTTON];

export type ReceivedProps = ISimpleList;

const useSimpleList = (props: ReceivedProps) => {
  const {
    attributes: {
      scrollActive,
      rowCount,
      items,
      multiSelect,
      multiSelectSecond,
      checkbox,
    },
    dataBinding,
    page,
    totalPage,
    onPress,
    originX = 1,
    id,
  } = props;

  const {
    enabled: enabledMultiSelect,
    type: typeMultiSelect,
    text: textMultiSelect,
    icon: iconMultiSelect,
    upperCase: upperCaseMultiSelect,
    position: positionMultiSelect,
    buttonBackgroundColor: buttonBackgroundColorMultiSelect,
    textColor: textColorMultiSelect,
    fontFamily: fontFamilyMultiSelect,
    fontSize: fontSizeMultiSelect,
    borderRadius: borderRadiusMultiSelect,
    // borderWidth: borderWidthMultiSelect,
    height: heightMultiSelect,
    width: widthMultiSelect,
  } = multiSelect || {};

  const {
    enabled: enabledMultiSelectSecond,
    type: typeMultiSelectSecond,
    text: textMultiSelectSecond,
    icon: iconMultiSelectSecond,
    upperCase: upperCaseMultiSelectSecond,
    position: positionMultiSelectSecond,
    buttonBackgroundColor: buttonBackgroundColorMultiSelectSecond,
    textColor: textColorMultiSelectSecond,
    fontFamily: fontFamilyMultiSelectSecond,
    fontSize: fontSizeMultiSelectSecond,
    borderRadius: borderRadiusMultiSelectSecond,
    // borderWidth: borderWidthMultiSelectSecond,
    height: heightMultiSelectSecond,
    width: widthMultiSelectSecond,
  } = multiSelectSecond || {};

  const [selectOptions, setSelectOptions] = useState<{
    ids: string[];
  }>({
    ids: [],
  });

  const isShowButtonMultiSelectTop =
    enabledMultiSelect && positionMultiSelect === POSITION_BUTTON.TOP;
  const isShowButtonMultiSelectBottom =
    enabledMultiSelect && positionMultiSelect === POSITION_BUTTON.BOTTOM;
  const isShowButtonMultiSelectSecondTop =
    enabledMultiSelectSecond &&
    positionMultiSelectSecond === POSITION_BUTTON.TOP;
  const isShowButtonMultiSelectSecondBottom =
    enabledMultiSelectSecond &&
    positionMultiSelectSecond === POSITION_BUTTON.BOTTOM;

  const isFixedHeight = {
    contentContainerStyle: scrollActive
      ? { flexGrow: 0, height: 'auto', margin: 0 }
      : undefined,
  };

  const tableId = get(items, 'source.tableId');

  const search = useMemo(
    () => (!isEmpty(window) ? queryString.parse(window?.location?.search) : {}),
    []
  );

  const target = search?.target;

  const emptyList = (target && !tableId) || (!tableId && Platform.OS !== 'web');
  const isShowFooter = totalPage > page;

  const listData = useMemo(() => {
    let len = rowCount || 3;

    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    } else {
      // web
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return getListItems(items, len);
      }
    }
  }, [dataBinding, items, rowCount, target]);

  const getColorCheckbox = (id?: string) => {
    if (!id) {
      return selectOptions.ids.length === listData?.length
        ? isCheckColor(checkbox?.activeColor)
          ? checkbox?.activeColor
          : '#4259AC'
        : isCheckColor(checkbox?.inactiveColor)
        ? checkbox?.inactiveColor
        : '#bbb';
    }

    return selectOptions.ids.includes(id)
      ? isCheckColor(checkbox?.activeColor)
        ? checkbox?.activeColor
        : '#4259AC'
      : isCheckColor(checkbox?.inactiveColor)
      ? checkbox?.inactiveColor
      : '#bbb';
  };

  const getStatusCheckbox = (id?: string) => {
    if (!id) {
      return selectOptions.ids.length === listData?.length
        ? 'check'
        : 'border-all-variant';
    }

    return selectOptions.ids.includes(id) ? 'check' : 'border-all-variant';
  };

  const handlePress = (
    id: 'onPress' | LIST_ITEM_MODE,
    item: Record<string, any>
  ) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };

    onPress && onPress(getActions(props, id), options);
  };

  const handleSelectIds = (id: string) => {
    setSelectOptions((prev) => ({
      ...prev,
      ids: prev.ids.includes(id)
        ? prev.ids.filter((items) => items !== id)
        : [...prev.ids, id],
    }));
  };

  const handleSelectAll = (listItem: Record<string, any>[]) =>
    setSelectOptions((prev) => ({
      ...prev,
      ids:
        prev.ids.length === listData?.length
          ? []
          : listItem.map((item) => item.id),
    }));

  const handleMultiSelect = (id: ID_BUTTON) => () => {
    onPress &&
      onPress(getActions(props, id), { ids: selectOptions.ids })
        .then((data: Record<string, any>) => {
          if (data.status === 'SUCCESS') {
            setSelectOptions((prev) => ({
              ...prev,
              ids: [],
            }));
          }
          return data;
        })
        .catch(() => {});
  };

  useEffect(() => {
    setSelectOptions((prev) => ({
      ...prev,
      ids: [],
    }));
  }, [id]);

  const buttonProps: Record<
    ID_BUTTON,
    React.ComponentProps<typeof RNButton>
  > = {
    [ID_BUTTON.MULTI_SELECT]: {
      mode: typeMultiSelect,
      children: textMultiSelect,
      icon: iconMultiSelect,
      color: buttonBackgroundColorMultiSelect,
      uppercase: upperCaseMultiSelect,
      style: {
        borderRadius: borderRadiusMultiSelect,
        height: heightMultiSelect,
        width: widthMultiSelect * originX,
        justifyContent: 'center',
        maxWidth: '100%',
        borderColor:
          typeMultiSelect === 'contained'
            ? buttonBackgroundColorMultiSelect
            : textColorMultiSelect,
      },
      labelStyle: {
        fontFamily: checkFont(fontFamilyMultiSelect, undefined, target),
        fontSize: fontSizeMultiSelect,
        ...(fontSizeMultiSelect && {
          lineHeight: fontSizeMultiSelect * 1.15,
          paddingVertical: 1.5,
        }),
        color: textColorMultiSelect,
      },
    },
    [ID_BUTTON.MULTI_SELECT_SECOND]: {
      mode: typeMultiSelectSecond,
      children: textMultiSelectSecond,
      icon: iconMultiSelectSecond,
      color: buttonBackgroundColorMultiSelectSecond,
      uppercase: upperCaseMultiSelectSecond,
      style: {
        borderRadius: borderRadiusMultiSelectSecond,
        height: heightMultiSelectSecond,
        width: widthMultiSelectSecond * originX,
        justifyContent: 'center',
        maxWidth: '100%',
        borderColor:
          typeMultiSelectSecond === 'contained'
            ? buttonBackgroundColorMultiSelectSecond
            : textColorMultiSelectSecond,
      },
      labelStyle: {
        fontFamily: checkFont(fontFamilyMultiSelectSecond, undefined, target),
        fontSize: fontSizeMultiSelectSecond,
        ...(fontSizeMultiSelectSecond && {
          lineHeight: fontSizeMultiSelectSecond * 1.15,
          paddingVertical: 1.5,
        }),
        color: textColorMultiSelectSecond,
      },
    },
  };

  return {
    ...props,
    selectOptions,
    emptyList,
    listData,
    search,
    isFixedHeight,
    isShowFooter,
    buttonProps,
    target,
    handlePress,
    handleSelectIds,
    handleMultiSelect,
    handleSelectAll,
    getColorCheckbox,
    getStatusCheckbox,
    isShowButtonMultiSelectTop,
    isShowButtonMultiSelectBottom,
    isShowButtonMultiSelectSecondTop,
    isShowButtonMultiSelectSecondBottom,
  };
};

export type Props = ReturnType<typeof useSimpleList>;

export default useSimpleList;
