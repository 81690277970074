import React, { useMemo } from 'react';
import useLeftRightSection, {
  Props,
  ReceivedProps,
  TYPE,
} from './useLeftRightSection';
import { View } from 'react-native';
import ImageCus from './components/Image';
import AvatarCus from './components/Avatar';
import ToggleCus from './components/Toggle';
import IconCus from './components/Icon';

const LeftRightSectionLayout = (props: Props) => {
  const { attributes, placeholderImage, getImage, handlePress, isCanvas } =
    props;

  const renderItem = useMemo(() => {
    switch (attributes.type) {
      case TYPE.AVATAR:
        return (
          <AvatarCus
            avatarURL={getImage(TYPE.AVATAR)}
            placeholder={placeholderImage()}
            attributes={attributes}
            // isCanvas={isCanvas}
          />
        );
      case TYPE.IMAGE:
        return (
          <ImageCus
            imageURL={getImage(TYPE.IMAGE)}
            placeholder={placeholderImage()}
            attributes={attributes}
            isCanvas={isCanvas}
          />
        );
      case TYPE.TOGGLE:
        return <ToggleCus attributes={attributes} />;

      default:
        return (
          <IconCus
            icon={attributes?.icon}
            color={attributes?.iconColor}
            handlePress={handlePress}
          />
        );
    }
  }, []);

  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderItem}
    </View>
  );
};

const LeftRightSection = (props: ReceivedProps) => {
  return <LeftRightSectionLayout {...useLeftRightSection(props)} />;
};

export default LeftRightSection;
