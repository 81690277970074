import { IQRCode } from '@nocode/types/';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { QRCode } from 'react-qr-svg';
import { TouchableOpacity } from 'react-native';
import { getValueBinding, getItemListClick, getActions } from '../shared';
import createStyelse from './style';
import { View } from 'react-native';

const QRCodeGenerate: React.FC<IQRCode> = (props) => {
  const styles = createStyelse(props);
  const { onPress } = props;
  const QRCodeValue = useMemo(() => {
    const isItemList = !!props?.record;
    const bindingValue = getValueBinding(
      isItemList ? props.initId : props.id,
      isItemList ? props?.record : props.data,
      props
    );
    return String(get(bindingValue, 'value'));
  }, [props]);

  const handlePress = (id: string) => {
    onPress &&
      onPress(getActions(props, id), {
        itemListClick: getItemListClick(props.record),
      });
  };

  const utf8ForQRCode = unescape(encodeURIComponent(QRCodeValue));

  return (
    <>
      {QRCodeValue ? (
        <TouchableOpacity
          style={styles.container}
          onPress={() => handlePress('onPress')}
        >
          <View style={styles.qrCodeInner}>
            <QRCode
              value={utf8ForQRCode}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
            />
          </View>
        </TouchableOpacity>
      ) : null}
    </>
  );
};

export default QRCodeGenerate;
