import { get, isArray, isEmpty, some, filter } from 'lodash';
import { config } from './config';
import { IObj } from '@common/types';
import { StyleSheet } from 'react-native';

const dataTypes = ['text', 'boolean', 'number', 'object', 'image', 'video'];

type Iconfig = {
  props: Record<string, any>[];
  childComponents?: Record<string, any>[];
  type: string;
  name: string;
};

export const getConfig = (object: Record<string, any>) =>
  get(config, object?.componentName, {});

const filterProps = (arr: any[] | undefined) => {
  if (!arr) return [];
  return filter(arr, (item: Iconfig) => dataTypes.includes(item?.type)).map(
    (item) => item?.name
  );
};

const filterChild = (arr: any[] | undefined) => {
  if (!arr) return [];
  return filter(
    arr.map((item: Iconfig) => {
      const propsValue = filterProps(item.props);
      if (propsValue.length > 0) {
        return propsValue.map((prop) => `${item.name}.${prop}`);
      }
    })
  ).flat();
};

export const getPathLibrary = (config: Iconfig): string[] => {
  if (!config) return [];
  return [
    ...filterProps(config.props),
    ...filterChild(config?.childComponents),
  ];
};

export const arrayBinding = (value: any) => {
  if (isArray(value)) {
    return some(value, (item) => item?.type === 'binding');
  } else return isEmpty(value);
};

const singleQuote = (text: string) => `${text}`;

export const convertTextAttributes = (text: any) => {
  if (!text || (typeof text === 'object' && !isArray(text))) return '';
  if (isArray(text)) {
    // return text
    //   .filter((item: any) => typeof item === 'string' && !isEmpty(item))
    //   .join('');
    const arrayString = filter(
      text,
      (item) => typeof item === 'string' && !isEmpty(item)
    );

    return arrayString
      .map((source) => {
        const regex = new RegExp(/^.*(\\{2})*\n$/);
        const value = `${source ?? ''}`;
        return singleQuote(
          regex.test(value) ? value.replace(/\n$/, '') : value
        );
      })
      .join(' || ');
  }
  return text;
};

export const getValueBinding = (
  componentId: string,
  dataBinding: Record<string, any> = {},
  object: Record<string, any>
): Record<string, boolean | number | string> => {
  const { isInCustomList = false, id } = object;

  const paths = getPathLibrary(getConfig(object));
  return paths.reduce((acc, path) => {
    return {
      ...acc,
      [path]: get(
        dataBinding,
        !isInCustomList ? path : `${id}.${path}`,
        convertTextAttributes(get(object.attributes, path))
      ),
    };
  }, {});
};

export const getImageCardCover = (
  item: any,
  cardCover: Record<string, any>
) => {
  // if (!cardCover?.image) return undefined;
  const imageType = get(cardCover, 'image.imageType');
  const imageBinding = get(item, 'cardCover.image', null);

  switch (imageType) {
    case 'internal':
      return get(imageBinding, 'url', null);

    case 'uploaded':
      return get(cardCover, 'imageUrl', null);

    default:
      return get(
        item,
        'cardCover.imageUrl',
        imageBinding || convertTextAttributes(cardCover.imageUrl)
      );
  }
};

export const getItemListClick = (record: Record<string, any> | null = {}) => {
  if (isEmpty(record?._meta)) return {};
  const _meta = record?._meta;
  return {
    sortedItemNumber: record?.sortedItemNumber,
    [_meta?.datasourceId]: record?.id,
    [_meta?.databindingId]: record?.id,
    recordObj: {
      [_meta?.tableId]: record?.id,
      [_meta?.databindingId]: record?.id,
      [_meta.componentId]: record?.id,
    },
    ...(_meta?.web3Config && {
      web3Config: {
        [_meta?.tableId]: _meta.web3Config,
        [_meta?.datasourceId]: _meta.web3Config,
        [_meta?.databindingId]: _meta.web3Config,
      },
    }),
  };
};

export const getValueInputDependencies = (props: Record<string, any>) =>
  get(props, `dependencies.valueInputs.${props.id}`, '');

export const getDefaultValueInput = (props: Record<string, any>) =>
  get(props, `dependencies.defaultValues.${props.id}`, '');

const flattenObject = (obj: IObj): IObj => {
  if (isEmpty(obj)) return {};
  return Object.keys(obj).reduce((pre, curr) => {
    const currValue = obj[curr];
    return {
      ...pre,
      ...(!isEmpty(currValue) &&
      typeof currValue === 'object' &&
      !Array.isArray(currValue)
        ? { ...flattenObject(currValue) }
        : { [curr]: currValue }),
    };
  }, {});
};
export const getActions = (object: Record<string, any>, id: string) => {
  try {
    if (!id || typeof id !== 'string') return;
    const actionItem = get(object.attributes, id, {});
    const objectActions = flattenObject(actionItem);
    const groupAction = get(object.actions, object?.groupActionId);
    const wrapActionId = get(object, 'attributes.onPress.actionId');

    if (object?.allowListAction) return object?.groupActionId;
    if (!isEmpty(objectActions?.actionId)) return objectActions?.actionId;
    if (isEmpty(objectActions?.actionId) && !isEmpty(wrapActionId))
      return wrapActionId;
    else if (!isEmpty(groupAction)) return groupAction?.actions || [];
  } catch (error) {
    console.log('error', error);
  }
};

export const ONE_DAY = 24 * 60 * 60 * 1000;

export const flattedSourceString = (source: string | any): any => {
  if (typeof source === 'string') return source;

  if (isArray(source))
    return source.filter((item: any) => typeof item === 'string').join('');

  if (typeof source === 'object' && !isArray(source)) {
    if (source?.imageType) {
      if (source.imageType === 'imageUrl')
        return flattedSourceString(source?.imageUrl);
      return flattedSourceString(source?.imageUploaded);
    }
    if (source?.videoType) {
      if (source.videoType === 'videoUrl')
        return flattedSourceString(source?.videoUrl);
      return flattedSourceString(source?.videoUploaded);
    }
    return null;
  }
  return null;
};

export function getValueByKey(
  obj: Record<string | number, any>,
  key: string
): any {
  for (let k in obj) {
    if (k == key) return obj[k];
    if (typeof obj[k] == 'object' && obj[k] !== null) {
      let result = getValueByKey(obj[k], key);
      if (result) return result;
    }
  }
  return;
}
export const getValueBindingComponent = (attrs: any, path: string) => {
  const { data = {}, record = {}, initId = '', id = '' } = attrs;
  const defaultValue = flattedSourceString(get(attrs, path));

  let value;
  if (!isEmpty(record)) {
    // get data in custom list
    const valueBinding = getValueByKey(record, attrs.id);
    value = getValueByKey(valueBinding, path);
  } else {
    value = getValueByKey(data, path);
  }
  return typeof value === 'boolean' ? value : value || defaultValue;
};

const _isCheckColor = (iconColor: string) => {
  const regexColor = [
    {
      header: '#',
      regex: /^#[a-fA-F0-9]{3}$|^#[a-fA-F0-9]{6}$/,
    },
    {
      header: 'rgba',
      regex: /^rgba\((?:\s*\d+\s*,){3}\s*[\d\.]+\)$/,
    },
    {
      header: 'rgb',
      regex: /^rgb\((?:\s*\d+\s*,){2}\s*[\d]+\)$/,
    },
    {
      header: 'hsla',
      regex: /^hsla\(\s*\d+(\s*,\s*\d+\s*\%){2}\s*\,\s*[\d\.]+\)$/,
    },
    {
      header: 'hsl',
      regex: /^hsl\(\s*\d+\s*(\s*\,\s*\d+\%){2}\)$/,
    },
  ];
  const htmlColorNamesSpecial = ['currentcolor', 'inherit', 'transparent'];
  const formatRegex = regexColor.find((item) =>
    iconColor?.startsWith(item.header)
  );
  if (formatRegex) {
    return formatRegex.regex.test(iconColor);
  } else if (
    htmlColorNamesSpecial.includes(iconColor?.toLowerCase()) ||
    iconColor !== iconColor?.trim()
  ) {
    return false;
  } else {
    const style = new Option().style;
    style.color = iconColor;
    return style.color !== '';
  }
};

export const loadMoreStyles = StyleSheet.create({
  loadMoreWrap: { marginTop: 30 },
  loadMoreText: {
    fontSize: 18,
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
export const isCheckColor = (color: string) => {
  try {
    return _isCheckColor(color);
  } catch (error) {
    console.error('Error when check color', error, ' | color: ', color);
    return false;
  }
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), ms));
