import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { reduceValuesWithIds } from '@common/utils/binding';

export const formInputs = (state: RootState) => state.formInputs;

export const getValueFields = (state: RootState) => state.formInputs.values;

export const formValueSelector = (state: RootState) =>
  state.formInputs.formValue;

export const formFieldValueSelector = (id: string) =>
  createSelector(formValueSelector, (formValue) => formValue?.[id]);

export const inputValueSelector = (id: string) =>
  createSelector(getValueFields, (formValue) => formValue?.[id]);

export const getDefaultValueFields = (state: RootState) =>
  state.formInputs.defaultValues;

export const getValueInputsWithIds = (ids: string[]) =>
  createSelector(getValueFields, reduceValuesWithIds(ids));

export const getFormInputsWithIds = (ids: string[]) =>
  createSelector(formValueSelector, reduceValuesWithIds(ids));

export const getDefaultInputsWithIds = (ids: string[]) =>
  createSelector(getDefaultValueFields, reduceValuesWithIds(ids));
