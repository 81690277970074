import Loading from '@common/components/Loading';
import StatusBar from '@common/components/StatusBar';
import useBindingScreen from '@common/hooks/useBindingScreen';
import useLineAction from '@common/hooks/useLineAction';
import useScreenAction from '@common/hooks/useScreenAction';
import { appInfoSelector } from '@common/redux/selectors/app';
import { dimensionSelector } from '@common/redux/selectors/page';
import { useLocation } from '@common/routes/hooks';
import { PropRender } from '@common/types/';
import { getAttributesFixed, getAttributesNormal } from '@common/utils';
import { default as queryString, ParsedQuery } from 'query-string';
import React, { useMemo } from 'react';
import { Platform, SafeAreaView, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { loadingPageSelector } from '../../../redux/selectors/page';
import BackgroundImage from '../RenderScreen/BackgroundImage';
import FixedComponent from './FixedObject';
import LoseConnectLiveStream from './LoseConnectLiveStream';
import NormalComponent from './DynamicObject';
import createStyle from './styles';
import QrCodeScanner from '@common/components/QrCodeScanner';
import useDependencies from '@common/hooks/useDependencies';
import { isEqual } from 'lodash';

function Render({ screen }: PropRender) {
  const appInfo = useSelector(appInfoSelector);
  const dimension = useSelector(dimensionSelector);
  const loadingPage = useSelector(loadingPageSelector);

  const { search } = useLocation();

  const { hiddenStatusBar }: ParsedQuery<any> = useMemo(
    () => queryString.parse(search),
    [search]
  );

  const { dependencies } = useDependencies({
    obj: screen,
  });

  useBindingScreen({ screen, dependencies, appInfo });

  useLineAction({ dependencies });

  useScreenAction({ screen, dependencies });

  const isWeb = useMemo(() => appInfo?.platform === 'web', [appInfo]);

  const isShowStatusBar =
    !screen.isModal &&
    !(Platform.OS === 'android' || Platform.OS === 'ios') &&
    !hiddenStatusBar &&
    screen?.attributes?.statusBarStyle !== 'hidden';

  // const originX = dimension.width / screen.width;
  const originY = dimension.height / screen.height;

  const arrNormal = useMemo(() => getAttributesNormal(screen), [screen]);
  const arrFixed = useMemo(() => getAttributesFixed(screen), [screen]);
  const styles = createStyle(screen);

  if (loadingPage) return <Loading />;

  return (
    <View style={styles.container}>
      {screen?.attributes?.backgroundImage && (
        <BackgroundImage
          source={screen?.attributes?.backgroundImage}
          resizeMode={screen?.attributes?.backgroundSize}
          positionX={screen?.attributes?.backgroundPositionX}
          positionY={screen?.attributes?.backgroundPositionY}
        />
      )}

      {!isWeb && (
        <StatusBar
          isShowStatusBar={isShowStatusBar}
          barStyle={screen?.attributes?.statusBarStyle}
        />
      )}

      <QrCodeScanner />

      <SafeAreaView style={styles.safeViewContainer}>
        <LoseConnectLiveStream screen={screen} originY={originY} />

        <NormalComponent arrNormal={arrNormal} screen={screen} />
      </SafeAreaView>

      <FixedComponent arrFixed={arrFixed} screen={screen} />
    </View>
  );
}

export default React.memo(Render, isEqual);
