import React, { memo, useCallback } from 'react';
import { Button as RNButton } from 'react-native-paper';

import { APPS_SHARE } from '@common/constants/shareApp.constant';
import { Image, Modal, Text, TouchableOpacity } from 'react-native';
import { View } from 'react-native-animatable';
import Icon from 'react-native-vector-icons/AntDesign';
import styles from './style';
import { IAppInfo } from '@common/types/element';

interface Props {
  onClose: () => void;
  isShow: boolean;
  appData?: IAppInfo;
}
const SharePopup: React.FC<Props> = ({ onClose, isShow, appData }) => {
  const handleClose = () => {
    onClose();
  };

  const handleClickShare = useCallback((shareLink: string): void => {
    window.open(shareLink, '_blank');
  }, []);

  const copyToClipboard = (value: string) => {
    if (value) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator?.clipboard?.writeText(value).then(() => alert('Copied!'));
      } else {
        const linkCopy = document.createElement('textarea');
        linkCopy.value = value;
        document.body.appendChild(linkCopy);
        linkCopy.focus();
        linkCopy.select();
        document.execCommand('copy');
        linkCopy.remove();
        alert('Copied!');
      }
    } else {
      alert('error ?');
    }
  };

  const handleCopy = useCallback((postUrl) => {
    copyToClipboard(postUrl);
  }, []);

  const renderOptionShare = useCallback(() => {
    return (
      <View style={styles.optionList}>
        {APPS_SHARE.map(({ icon, name, getLink }, index: number) => (
          <TouchableOpacity
            key={index}
            style={styles.optionTouch}
            onPress={() => handleClickShare(getLink())}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Image
                style={{
                  resizeMode: 'contain',
                  width: 66,
                  height: 66,
                  borderRadius: 66 / 2,
                  padding: 7,
                }}
                source={{
                  uri: icon,
                }}
              />
            </View>
            <Text style={{}}>{name}</Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  }, [handleClickShare]);

  const getTargetUrl = window.location.href;

  const renderCopyLink = useCallback(() => {
    const iframeValue = `<iframe src="${getTargetUrl}" allowpaymentrequest="true" allow="camera; microphone;" allowusermedia frameborder="0" style="width: 100%; height: 100dvh; border: none;" title="${
      appData?.name || ''
    }"></iframe>`;
    return (
      <View>
        <Text style={styles.optionCopyTitle}>アプリの共有URL</Text>
        <View style={[styles.optionCopyLink]}>
          <View style={{ width: '88%' }}>
            <Text numberOfLines={1}>
              {
                // getPostUrl()
                getTargetUrl
              }
            </Text>
          </View>
          <RNButton onPress={() => handleCopy(getTargetUrl)}>
            <Text style={styles.optionButtonName}>{'Copy'}</Text>
          </RNButton>
        </View>
        <Text style={styles.optionCopyTitle}>サイトへの埋め込み(iframe)</Text>
        <View style={[styles.optionCopyLink]}>
          <View style={{ width: '88%' }}>
            <Text numberOfLines={2}>{iframeValue}</Text>
          </View>
          <RNButton onPress={() => handleCopy(iframeValue)}>
            <Text style={styles.optionButtonName}>{'Copy'}</Text>
          </RNButton>
        </View>
      </View>
    );
  }, [handleCopy]);

  return (
    <TouchableOpacity style={styles.centeredView} onPress={handleClose}>
      <Modal
        visible={isShow}
        animationType="fade"
        transparent={true}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTop}>
              <Text style={styles.modalTopTitle}>アプリの共有</Text>
              <Icon
                style={{
                  marginLeft: 'auto',
                  marginVertical: 'auto',
                }}
                name={'close'}
                size={14}
                onPress={handleClose}
              />
            </View>
            <View style={styles.modalBody}>
              <View style={styles.modalBodyContent}>
                {renderOptionShare()}
                {renderCopyLink()}
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </TouchableOpacity>
  );
};

export default memo(SharePopup);
