import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from './Icon';

type Props = {
  attributes: Record<string, any>;
};

const Toggle = ({ attributes }: Props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <TouchableOpacity
      style={{
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
      onPress={() => {
        setIsActive(!isActive);
      }}
    >
      {isActive ? (
        <Icon icon={attributes?.activeIcon} color={attributes?.activeColor} />
      ) : (
        <Icon
          icon={attributes?.inactiveIcon}
          color={attributes?.inactiveColor}
        />
      )}
    </TouchableOpacity>
  );
};

export default Toggle;
