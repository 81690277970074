import {
  realtimeDatabaseSelector,
  actionRefreshSelector,
} from '@common/redux/selectors/database';
import { extractTableIdsFromObject } from '@common/utils/component';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type ReceivedProps = { obj: any };

const useRefreshData = (props: ReceivedProps) => {
  const { obj } = props;
  const databases = useSelector(realtimeDatabaseSelector);
  const actions = useSelector(actionRefreshSelector);

  const tableRefreshIds = useMemo(
    () => extractTableIdsFromObject(obj ?? {}),
    [obj]
  );

  const databaseRefresh = useMemo(() => {
    const databaseChange = tableRefreshIds.reduce(
      (preValue: any, current: string) => {
        return { ...preValue, [current]: databases[current] };
      },
      {}
    );
    return JSON.stringify(databaseChange);
  }, [databases, tableRefreshIds]);

  const actionRefresh = useMemo(() => {
    const databaseChange = tableRefreshIds.reduce(
      (preValue: any, current: string) => {
        return { ...preValue, [current]: actions[current] };
      },
      {}
    );
    return JSON.stringify(databaseChange);
  }, [actions, tableRefreshIds]);

  return {
    ...props,
    databaseRefresh,
    actionRefresh,
  };
};

export type Props = ReturnType<typeof useRefreshData>;

export default useRefreshData;
