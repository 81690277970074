import React from 'react';
import { findLastIndex, get, isNil } from 'lodash';
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import { List, ToggleButton, Button as RNButton } from 'react-native-paper';
import EmptyList from '../EmptyList';
import { checkFont } from '../func';
import { loadMoreStyles } from '../shared';
import Loading from '../Loading';
import createStyles from './style';
import PointIcon from '../CustomIcon';
import useSimpleList, {
  ID_BUTTON,
  LIST_ITEM_MODE,
  Props,
  ReceivedProps,
} from './useSimpleList';
import LeftRightSection from './LeftRightSection';
import { getValueBinding } from '../shared';

const SimpleListLayout = (props: Props) => {
  const {
    attributes: {
      listHeader,
      subTitle,
      subTitle2,
      leftSection,
      rightSection,
      dividerType,
      scrollActive,
      iconColor,
      opacity,
      backgroundColor,
      borderColor,
      borderWidth,
      borderRadius,
      fontFamily,
      fontSize,
      color,
      title,
      multiSelect,
      multiSelectSecond,
      checkbox,
    },
    isWeb,
    onLoadMore,
    isLoadMore,
    initializeList,
    handlePress,
    handleSelectIds,
    handleMultiSelect,
    emptyList,
    handleSelectAll,
    listData,
    search,
    isFixedHeight,
    isShowFooter,
    buttonProps,
    target,
    height,
    getColorCheckbox,
    getStatusCheckbox,
    isShowButtonMultiSelectTop,
    isShowButtonMultiSelectBottom,
    isShowButtonMultiSelectSecondTop,
    isShowButtonMultiSelectSecondBottom,
    selectOptions,
  } = props;

  const { enabled: enabledMultiSelect } = multiSelect || {};
  const { enabled: enabledMultiSelectSecond } = multiSelectSecond || {};
  const isDisabled = selectOptions?.ids?.length === 0;
  const styles = createStyles(props);

  if (emptyList) {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );
  }

  if (initializeList) {
    return <Loading />;
  }

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (isShowFooter) {
            onLoadMore();
          }
        }}
        style={loadMoreStyles.loadMoreWrap}
      >
        {isLoadMore ? (
          <Loading />
        ) : (
          <Text style={loadMoreStyles.loadMoreText}>もっと見る</Text>
        )}
      </TouchableOpacity>
    );
  };

  const ItemList = ({
    item,
    index,
  }: {
    item: Record<string, any>;
    index: number;
  }) => {
    const bindingForItem = getValueBinding('', item, props);

    return (
      <List.Item
        key={index}
        title={get(bindingForItem, 'title.text')}
        titleNumberOfLines={title && title.titleLineNum > 1 ? 500 : 1}
        onPress={() => {
          handlePress('onPress', {
            record: item,
            indexRecord: index,
          });
        }}
        description={() => (
          <View>
            {subTitle.enabled && (
              <Text
                numberOfLines={
                  subTitle && subTitle.subtitleLineNum > 1 ? 500 : 1
                }
                style={{
                  fontSize,
                  fontFamily: checkFont(fontFamily),
                  color,
                }}
              >
                {get(bindingForItem, 'subTitle.text')}
              </Text>
            )}
            {subTitle2.enabled && (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: checkFont(fontFamily),
                  color,
                }}
                numberOfLines={
                  subTitle2 && subTitle2.subtitle2LineNum > 1 ? 500 : 1
                }
              >
                {get(bindingForItem, 'subTitle2.text')}
              </Text>
            )}
          </View>
        )}
        left={() => (
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {(enabledMultiSelect || enabledMultiSelectSecond) && (
              <ToggleButton
                onPress={() => handleSelectIds(item.id)}
                status={'unchecked'}
                icon={getStatusCheckbox(item.id)}
                color={getColorCheckbox(item.id)}
                size={checkbox?.size ?? 16}
              />
            )}
            {leftSection?.enabled ? (
              <LeftRightSection
                attributes={{
                  ...(leftSection || {}),
                  iconColor,
                }}
                record={item}
                mode={LIST_ITEM_MODE.LEFT_SECTION}
              />
            ) : null}
          </View>
        )}
        right={() =>
          rightSection?.enabled ? (
            <LeftRightSection
              attributes={{
                ...(rightSection || {}),
                iconColor,
              }}
              record={item}
              mode={LIST_ITEM_MODE.RIGHT_SECTION}
              handlePress={() =>
                handlePress(LIST_ITEM_MODE.RIGHT_SECTION, {
                  record: item,
                  indexRecord: index,
                })
              }
            />
          ) : null
        }
        style={{
          borderTopWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index === 0 && dividerType && dividerType === 'line'
              ? 0
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderBottomWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index !== findLastIndex(listData)
              ? 1
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderColor: '#e2e1e1',
          justifyContent: 'center',
          ...(dividerType && dividerType === 'shadow' && { borderRadius: 20 }),
        }}
        titleStyle={{
          //error font family________________
          fontFamily: checkFont(fontFamily),
          fontSize,
          color,
        }}
        descriptionStyle={{
          //error font family________________
          fontFamily: checkFont(fontFamily),
          fontSize,
          color,
        }}
      />
    );
  };

  const SubmitButton = ({
    buttonProp,
    id,
    disabled,
  }: {
    buttonProp: React.ComponentProps<typeof RNButton>;
    id: ID_BUTTON;
    disabled: boolean;
  }) => {
    const { icon } = buttonProp || {};

    if (icon === 'point') {
      let copyChildProps = { ...buttonProp };
      delete copyChildProps.icon;

      return (
        <View style={styles.buttonWrapper}>
          <RNButton
            {...copyChildProps}
            onPress={handleMultiSelect(id)}
            icon={() => <PointIcon width={24} height={24} fill={color} />}
            disabled={disabled}
          />
        </View>
      );
    }

    return (
      <View style={styles.buttonWrapper}>
        <RNButton {...buttonProp} onPress={handleMultiSelect(id)} disabled={disabled} />
      </View>
    );
  };

  const SelectAllBox = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 8,
        }}
      >
        <ToggleButton
          onPress={() => handleSelectAll(listData)}
          status={'unchecked'}
          icon={getStatusCheckbox()}
          color={getColorCheckbox()}
          size={checkbox?.size ?? 16}
        />
        <Text style={{ fontFamily: checkFont(fontFamily), fontSize, color }}>
          {search?.locale !== 'en' ? '全て選択する' : 'Select All'}
        </Text>
      </View>
    );
  };

  const ListWrapper = () => (
    <View>
      {(isShowButtonMultiSelectTop || isShowButtonMultiSelectSecondTop) && (
        <View style={styles.selectOptionButton}>
          {isShowButtonMultiSelectTop && (
            <View style={styles.selectOptionButtonInner}>
              <SubmitButton
                buttonProp={buttonProps[ID_BUTTON.MULTI_SELECT]}
                id={ID_BUTTON.MULTI_SELECT}
                disabled={isDisabled}
              />
            </View>
          )}
          {isShowButtonMultiSelectSecondTop && (
            <View style={styles.selectOptionButtonInner}>
              <SubmitButton
                buttonProp={buttonProps[ID_BUTTON.MULTI_SELECT_SECOND]}
                id={ID_BUTTON.MULTI_SELECT_SECOND}
                disabled={false}
              />
            </View>
          )}
        </View>
      )}
      <View>
        {(enabledMultiSelect || enabledMultiSelectSecond) && <SelectAllBox />}
        <View>
          <ScrollView
            {...isFixedHeight}
            nestedScrollEnabled
            showsHorizontalScrollIndicator={false}
          >
            {listData.map((item, index) => (
              <ItemList item={item} index={index} />
            ))}
            {isShowFooter && <ListFooterComponent />}
          </ScrollView>
        </View>
      </View>
      {(isShowButtonMultiSelectBottom ||
        isShowButtonMultiSelectSecondBottom) && (
        <View style={styles.selectOptionButton}>
          {isShowButtonMultiSelectBottom && (
            <View style={styles.selectOptionButtonInner}>
              <SubmitButton
                buttonProp={buttonProps[ID_BUTTON.MULTI_SELECT]}
                id={ID_BUTTON.MULTI_SELECT}
                disabled={isDisabled}
              />
            </View>
          )}
          {isShowButtonMultiSelectSecondBottom && (
            <View style={styles.selectOptionButtonInner}>
              <SubmitButton
                buttonProp={buttonProps[ID_BUTTON.MULTI_SELECT_SECOND]}
                id={ID_BUTTON.MULTI_SELECT_SECOND}
                disabled={false}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );

  return (
    <>
      <List.Section
        style={{
          ...styles,
          opacity,
          ...(listData && listData.length > 0 ? { backgroundColor } : {}),
          borderColor,
          borderWidth,
          borderRadius,
          ...(isWeb && {
            marginTop: 0,
            marginBottom: 0,
            height: height - 20,
            overflowX: 'hidden',
            overflowY: listData.length > 0 ? 'visible' : 'hidden',
          }),
        }}
      >
        {listHeader.enabled && <List.Subheader>{'Simple List'}</List.Subheader>}
        {listData.length > 0 &&
          (scrollActive && (Platform.OS !== 'web' || !isNil(target)) ? (
            <View style={{ height: height, margin: 0 }}>
              <ListWrapper />
            </View>
          ) : (
            <ListWrapper />
          ))}
      </List.Section>
    </>
  );
};

const SimpleList = (props: ReceivedProps) => {
  return <SimpleListLayout {...useSimpleList(props)} />;
};

export default SimpleList;
