// import { Platform } from 'react-native';
import { StyleSheet } from 'react-native';
import { POSITION_BUTTON, Props } from './useSimpleList';

// const GAP_BUTTON = 20;

const createStyles = (props: Props) => {
  const {
    attributes: { multiSelect, multiSelectSecond },
  } = props;

  const { enabled: enabledMultiSelect, position: positionMultiSelect } =
    multiSelect || {};

  const {
    enabled: enabledMultiSelectSecond,
    position: positionMultiSelectSecond,
  } = multiSelectSecond || {};

  const checkGapButton = () => {
    const isSamePosition =
      (positionMultiSelect === POSITION_BUTTON.TOP &&
        positionMultiSelectSecond === POSITION_BUTTON.TOP) ||
      (positionMultiSelect === POSITION_BUTTON.BOTTOM &&
        positionMultiSelectSecond === POSITION_BUTTON.BOTTOM);

    return enabledMultiSelect && enabledMultiSelectSecond && isSamePosition;
  };

  return StyleSheet.create({
    leftSection: {
      // backgroundColor: '#cccccc',
      borderRadius: 100,
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightSection: {
      borderRadius: 100,
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectOptionButton: {
      justifyContent: 'space-evenly',
      alignItems: 'center',
      ...(checkGapButton() && {
        // gap: GAP_BUTTON,
        flexDirection: 'row',
      }),
    },
    selectOptionButtonInner: {
      // flex: 1,
      alignItems: 'center',
      ...(checkGapButton() &&
        {
          // maxWidth:
          //   Platform.OS === 'web'
          //     ? `calc((100% - ${GAP_BUTTON}px) / 2)`
          //     : (width - GAP_BUTTON) / 2,
          // maxWidth: width - (widthMultiSelect + widthMultiSelectSecond),
        }),
      overflow: 'hidden',
    },
    buttonWrapper: { alignItems: 'center', maxWidth: '100%' },
  });
};

export default createStyles;
