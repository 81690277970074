import { IBookingCalendar } from '@nocode/types';
import {
  BindingItemType,
  BindingItemTypeFormatted,
  BookingCalendarHolidayResponse,
} from '@nocode/types/bookingCalendar.type';
import { get } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { getActions, getItemListClick, getValueBinding } from '../shared';

const TOTAL_MINUTE_IN_A_DAY = 24 * 60;
export const RATIO_TEXT_CONTENT = 0.8;
type BindingValue = {
  timeDuration: string;
  'customInitializeTime.enabled': boolean;
  'customInitializeTime.hour': string | number;
  'customInitializeTime.minute': string | number;
};

const parseMinuteToTimeLabel = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minute = minutes - hours * 60;
  return `${hours.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}`;
};

/**
 * @param {string} timeString hh:mm
 * @param {number} durationMinute 30
 */
const getIndexFromTimeString = ({
  durationMinute,
  timeString,
}: {
  timeString: string;
  durationMinute: number;
}) => {
  const [h, m] = timeString.split(':');
  const minutes = +h * 60 + +m;
  return Math.floor(minutes / durationMinute);
};

const getMockData = (durationMinute: number): BindingItemType[] => {
  const currentTime = moment();
  return [
    {
      id: `available`,
      stockAvailable: '2',
      minimumStockAvailable: '1',
      eventStarttime: currentTime.startOf('day').add(1, 'day').toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
    {
      id: `fewAvailable`,
      stockAvailable: '1',
      minimumStockAvailable: '1',
      eventStarttime: currentTime
        .startOf('day')
        .add(durationMinute, 'minutes')
        .add(2, 'day')
        .toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
    {
      id: `notAvailable`,
      stockAvailable: '0',
      minimumStockAvailable: '1',
      eventStarttime: currentTime
        .startOf('day')
        .add(durationMinute * 2, 'minutes')
        .add(3, 'day')
        .toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
  ];
};

const getJpHoliday = async (): Promise<BookingCalendarHolidayResponse> => {
  const url = 'https://holidays-jp.github.io/api/v1/date.json';
  return fetch(url).then((r) => {
    if (r.ok) {
      return r.json();
    }
    return {};
  });
};

export const useBookingCalendar = (props: IBookingCalendar) => {
  const [beginDate, setBeginDate] = useState(moment().startOf('day').toDate());
  const [initializeTimeIndex, setInitializeTimeIndex] = useState(0);
  const [holidays, setHolidays] = useState<BookingCalendarHolidayResponse>();
  const listRef = useRef<any>(null);
  const { onPress, dataBinding = [], attributes } = props;
  const { timeDuration = '30', initializeList } = attributes;
  const isCanvas =
    Platform.OS === 'web' &&
    !queryString.parse(window?.location?.search)?.target;
  const durationMinute = +timeDuration;

  const getInitValues = useCallback((): BindingItemType[] => {
    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    }
    // web
    const search = queryString.parse(window?.location?.search);
    const target = search?.target;
    if (target) {
      // preview
      return dataBinding;
    }
    // return mock data on canvas
    return getMockData(durationMinute);
  }, [dataBinding]);
  const bindingValue = useMemo(
    () =>
      getValueBinding(
        props.id,
        props.data || dataBinding[0] || {},
        props
      ) as BindingValue,
    [props.id]
  );
  const rawData = useMemo(
    () => getInitValues().filter((e) => moment(e.eventStarttime).isValid()),
    [getInitValues]
  );
  const data = useMemo(
    () =>
      rawData
        .map<BindingItemTypeFormatted>((item) => ({
          createdAt: new Date(item.createdAt),
          eventStarttime: new Date(item.eventStarttime),
          id: item.id,
          minimumStockAvailable: Number(item.minimumStockAvailable) || 0,
          stockAvailable: Number(item.stockAvailable) || 0,
          updatedAt: new Date(item.updatedAt),
        }))
        .sort((a, b) => moment(a.eventStarttime).diff(b.eventStarttime)),
    [rawData]
  );

  const itemDisplay = useMemo(() => {
    return data.filter((item) =>
      moment(item.eventStarttime).isBetween(
        moment(beginDate),
        moment(beginDate).add(1, 'week')
      )
    );
  }, [data, beginDate]);

  /**
   * Example:
   [
   '00:00': {'2024-10-23': [], ..., '2024-10-30': []},
   '00:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ...
   '11:30': {'2024-10-23': [], ..., '2024-10-30': []},
   '12:00': {'2024-10-23': [], ..., '2024-10-30': []},
   '12:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ...
   '23:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ]
   */
  const durationMapping = useMemo(() => {
    const durationMapping: Record<
      string,
      Record<string, BindingItemTypeFormatted[]>
    > = {};
    const getInitDurationMappingItem = (): Record<
      string,
      BindingItemTypeFormatted[]
    > =>
      Array(7)
        .fill(0)
        .map((_, i) => moment(beginDate).add(i, 'day').format('YYYY-MM-DD'))
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: [],
          };
        }, {});
    for (
      let minuteRun = 0;
      minuteRun < TOTAL_MINUTE_IN_A_DAY;
      minuteRun += durationMinute
    ) {
      durationMapping[parseMinuteToTimeLabel(minuteRun)] =
        getInitDurationMappingItem();
    }
    const durationArrayKeys = Object.keys(durationMapping);
    itemDisplay.forEach((item) => {
      const timeString = moment(item.eventStarttime).format('HH:mm');
      const index = getIndexFromTimeString({
        timeString,
        durationMinute,
      });
      const durationArrayKey = durationArrayKeys[index];
      const date = moment(item.eventStarttime).format('YYYY-MM-DD');
      durationMapping[durationArrayKey]?.[date]?.push(item);
    });
    return durationMapping;
  }, [itemDisplay]);

  const onNextWeek = () => {
    setBeginDate((old) => {
      return moment(old).add(1, 'week').toDate();
    });
  };
  const onPreviousWeek = () => {
    setBeginDate((old) => {
      return moment(old).subtract(1, 'week').toDate();
    });
  };

  const handlePress = (id: string) => {
    const item = getInitValues().find((e) => e.id === id);
    if (item) {
      const options = {
        itemListClick: getItemListClick(item),
      };

      onPress && onPress(getActions(props, id), options);
    }
  };

  useEffect(() => {
    const currentTime = moment();
    let initializeTime = currentTime.format('HH:mm');
    if (isCanvas) {
      initializeTime = currentTime.startOf('date').format('HH:mm');
    } else if (bindingValue['customInitializeTime.enabled']) {
      const hour = get(bindingValue, 'customInitializeTime.hour');
      const minute = get(bindingValue, 'customInitializeTime.minute');
      const hourStr =
        Number.isInteger(+hour) && Number(hour) >= 0 && Number(hour) < 24
          ? Number(hour).toString().padStart(2, '0')
          : currentTime.format('HH');
      const minuteStr =
        Number.isInteger(+minute) && Number(minute) >= 0 && Number(minute) <= 60
          ? Number(minute).toString().padStart(2, '0')
          : currentTime.format('mm');
      initializeTime = `${hourStr}:${minuteStr}`;
    }
    const index = getIndexFromTimeString({
      durationMinute,
      timeString: initializeTime,
    });
    setInitializeTimeIndex(index);
  }, [bindingValue]);

  // scroll to current time
  useEffect(() => {
    if (!initializeList && listRef?.current) {
      setTimeout(() => {
        listRef.current?.scrollToOffset({
          animated: true,
          offset:
            initializeTimeIndex * attributes.lineHeight * RATIO_TEXT_CONTENT,
          x: 0,
        });
      }, 300);
    }
  }, [initializeList, initializeTimeIndex, listRef?.current]);

  // get holidays
  useEffect(() => {
    getJpHoliday().then((r) => setHolidays(r));
  }, []);

  return {
    durationMapping,
    beginDate,
    onPreviousWeek,
    onNextWeek,
    bindingValue,
    handlePress,
    initializeTimeIndex,
    listRef,
    holidays,
  };
};
