import React, { FC, PropsWithChildren, useRef } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native';
import { isEmpty } from 'lodash';

import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRectangle } from '@common/types/element';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import createStyles from './style';

const LinearGradientWrapper: React.FC<
  PropsWithChildren<{
    attrs: Pick<
      IRectangle,
      | 'isBgGradient'
      | 'bgGradientFirst'
      | 'bgGradientSecond'
      | 'backgroundColor'
      | 'startX'
      | 'startY'
      | 'endX'
      | 'endY'
    >;
    style: any;
  }>
> = ({ attrs, style, children }) => {
  const startX = attrs?.startX ? attrs?.startX : 0;
  const startY = attrs?.startY ? attrs?.startY : 0;
  const endX = attrs?.endX ? attrs?.endX : 100;
  const endY = attrs?.endY ? attrs?.endY : 0;
  return (
    <LinearGradient
      colors={[
        attrs?.bgGradientFirst || attrs.backgroundColor,
        attrs?.bgGradientSecond || attrs.backgroundColor,
      ]}
      style={style}
      start={{
        x: startX / 100,
        y: startY / 100,
      }}
      end={{
        x: endX / 100,
        y: endY / 100,
      }}
    >
      {children}
    </LinearGradient>
  );
};

const Rectangle: FC<IRectangle> = (attrs) => {
  const touchRef = useRef(null);

  const styles = createStyles(attrs);

  const hasAction = !isEmpty(attrs.actions);

  const layout = {
    offsetTop: attrs?.y,
    offsetLeft: attrs?.x,
    parentWidth: attrs?.width,
  };

  if (hasAction) {
    const handlePress = (e: GestureResponderEvent) => {
      let arrayAction = getActions(attrs.actions);
      const options = {
        itemListClick: getItemListClick(attrs?.record),
      };

      attrs.onPress && attrs.onPress(arrayAction, options);
    };

    if (attrs?.isBgGradient) {
      return (
        <LinearGradientWrapper attrs={attrs} style={styles.container}>
          <TouchableOpacity
            activeOpacity={1}
            ref={touchRef}
            onPress={handlePress}
          >
            {attrs?.children && (
              <ObjectRender
                arrComp={attrs?.children}
                isScreen={false}
                layout={{
                  offsetTop: attrs?.y,
                  offsetLeft: attrs?.x,
                  parentWidth: attrs?.width,
                }}
              />
            )}
          </TouchableOpacity>
        </LinearGradientWrapper>
      );
    }

    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        ref={touchRef}
        onPress={handlePress}
      >
        {attrs?.children && (
          <ObjectRender
            arrComp={attrs?.children}
            isScreen={false}
            layout={layout}
          />
        )}
      </TouchableOpacity>
    );
  }

  if (attrs?.isBgGradient) {
    return (
      <LinearGradientWrapper attrs={attrs} style={styles.container}>
        {attrs?.children && (
          <ObjectRender
            arrComp={attrs?.children}
            isScreen={false}
            layout={{
              offsetTop: attrs?.y,
              offsetLeft: attrs?.x,
              parentWidth: attrs?.width,
            }}
          />
        )}
      </LinearGradientWrapper>
    );
  }

  return (
    <View style={styles.container}>
      <ObjectRender
        keyItem={attrs?.selectedItem?.itemIndex}
        arrComp={attrs?.children || []}
        isScreen={false}
        layout={layout}
      />
    </View>
  );
};

export default Rectangle;
