const getCurrentPosition = async () => {
  return new Promise(function (resolve, reject) {
    return navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position && position.coords) {
          return resolve(position);
        }
        return reject('Could not found position');
      },
      (error) => {
        reject(error);
      },
      {
        timeout: 5000, // (ms)
        maximumAge: 60000, // (ms) If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real current position.
        enableHighAccuracy: true,
      }
    );
  });
};

export const getCurrentLocation = async () => {
  try {
    const currentPosition = await getCurrentPosition();
    return currentPosition;
  } catch (error: any) {
    console.error('===getCurrentLocation failure ===', error);
    return;
  }
};
