export default {
  name: 'Video',
  displayName: {
    en: 'Video',
    ja: '縦型動画',
  },
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'videoSource',
      displayName: { ja: '動画ソース', en: 'Video URL' },
      type: 'video',
    },
    {
      name: 'showVideoControl',
      displayName: {
        en: 'Show video control',
        ja: '動画操作の表示',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'autoPlay',
      displayName: {
        en: 'Auto play',
        ja: '自動再生',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'muted',
      displayName: {
        en: 'Mute',
        ja: 'ミュート',
      },
      type: 'boolean',
      default: false,
    },
    {
      name: 'loop',
      displayName: {
        en: 'Loop',
        ja: '繰り返し再生',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'fullscreen',
      displayName: {
        en: 'Full screen',
        ja: '全画面',
      },
      type: 'boolean',
      default: false,
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: '背景色',
      },
      type: 'color',
      default: '#ffffff',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'ClickFlow when Video played',
        ja: '動画再生時のClickFlow',
      },
    },
    {
      name: 'successActions',
      type: 'action',
      displayName: {
        en: 'ClickFlow when Video finished',
        ja: '動画終了時のClickFlow',
      },
    },
  ],
  childComponents: [
    {
      name: 'preview',
      displayName: {
        en: 'Preview Image',
        ja: '画像ソース',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'image',
          displayName: {
            en: 'Image URL',
            ja: '動画URL',
          },
          type: 'text',
        },
      ],
    },
  ],
};
