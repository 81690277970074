import { loadApp } from '@common/redux/slice/app';
import { fetchApp } from '@common/services';
import { IAppInfo } from '@common/types';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Platform } from 'react-native';
import Config from 'react-native-config';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import RNDeviceInfo from 'react-native-device-info';
import { DeviceInfo } from 'click-types';

let appJson = require('../json_data/appInfo.json');

type Props = {
  appId: string;
  isShareScreen?: boolean;
};

type APIResponse<T> = {
  data: T | undefined;
  error: string | null;
  isLoading: boolean;
};

const useAppDetail = ({ appId, isShareScreen }: Props) => {
  const dispatch = useDispatch();

  const [response, setResponse] = useState<APIResponse<IAppInfo>>({
    data: undefined,
    error: null,
    isLoading: true,
  });

  const getDeviceInfo = async (appMetadata: {
    deviceInfo?: { enable: boolean };
  }): Promise<DeviceInfo | null> => {
    if (!appMetadata?.deviceInfo?.enable) {
      return null;
    }
    return {
      buildId: await RNDeviceInfo.getBuildId().catch(() => ''),
      deviceId: RNDeviceInfo.getDeviceId(),
      deviceName: await RNDeviceInfo.getDeviceName().catch(() => ''),
      systemVersion: RNDeviceInfo.getSystemVersion(),
      version: RNDeviceInfo.getVersion(),
      uniqueId: await RNDeviceInfo.getUniqueId(),
      manufacturer: await RNDeviceInfo.getManufacturer().catch(() => ''),
      model: RNDeviceInfo.getModel(),
      totalDiskCapacity: await RNDeviceInfo.getTotalDiskCapacity().catch(
        () => -1
      ),
      userAgent: await RNDeviceInfo.getUserAgent().catch(() => ''),
      freeDiskStore: await RNDeviceInfo.getFreeDiskStorage().catch(() => -1),
    };
  };

  const _fetchData = useCallback(async () => {
    setResponse({ ...response, ...{ isLoading: true } });
    try {
      let appInfo;
      if (!isEmpty(appJson)) {
        appInfo = appJson;
      } else {
        const response = await fetchApp(appId);
        appInfo = response;
      }
      const appMetadata =
        typeof appInfo.metadata === 'string'
          ? JSON.parse(appInfo.metadata)
          : appInfo.metadata;

      dispatch(
        loadApp({
          ...appInfo,
          APP_VERSION: Config?.APP_VERSION,
          APP_NAME: Config?.APP_NAME,
          deviceInfo: await getDeviceInfo(appMetadata),
          metadata: appMetadata,
        })
      );

      setResponse({
        ...response,
        ...{
          isLoading: false,
          data: {
            ...appInfo,
            metadata:
              typeof appInfo.metadata === 'string'
                ? JSON.parse(appInfo.metadata)
                : appInfo.metadata,
          },
        },
      });
    } catch (error) {
      console.error('===Fetch app detail error', error);
      Alert.alert(
        '',
        'アプリが起動できません。電波状況が良好なエリアで再度実行してください。'
      );
      setResponse({
        ...response,
        ...{ isLoading: false, error: 'get data error' },
      });
    }
  }, [isShareScreen, appId]);

  useEffect(() => {
    _fetchData();
  }, [_fetchData]);

  return { ...response };
};

export default useAppDetail;
