import { get } from 'lodash';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { defaultURL } from '../../../utils/common.constant';
import { getActions, getImageCardCover, getItemListClick } from '../../shared';

const CarouselItem = (props: any) => {
  const { item, width, height, attrs, onPress } = props;
  const { attributes } = attrs;
  const { borderRadius, cardCover, backgroundColor } = attributes;
  const imageResize = cardCover?.image?.imageResize;

  const imageType = get(cardCover, 'image.imageType');

  const placeholderImageUrl = get(
    attributes,
    'cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attributes,
    'cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : defaultURL;

  const imageCanvas =
    typeof cardCover.imageUrl === 'string'
      ? cardCover.imageUrl || placeholderImage
      : Array.isArray(cardCover.imageUrl)
      ? cardCover.imageUrl[0] || placeholderImage
      : placeholderImage;

  const imageUrl = getImageCardCover(item, cardCover) || placeholderImage;

  const handlePress = () =>
    onPress(getActions(attrs, 'onPress'), {
      itemListClick: getItemListClick(item),
    });
  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[styles.container, { borderRadius, backgroundColor }]}
    >
      <Image
        source={{ uri: imageUrl }}
        style={[styles.keyImage, { width, height }]}
        resizeMethod="resize"
        resizeMode={imageResize}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  keyImage: {
    justifyContent: 'center',
    flex: 1,
    paddingRight: 8,
  },
});

export default CarouselItem;
