import { simpleBindingSelector } from '@common/redux/selectors/page';
import { IOnPressProps } from '@common/types/action';
import { get, isEmpty, pick } from 'lodash';
import React, { FC, useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

type Props = {
  ObjectClass: FC;
  obj: any;
  dependencies: any;
  onPress: (actionId: string, options: IOnPressProps) => any;
};

// const getVisibility = (props: { obj: any; data?: any }) => {
//   const { obj, data } = props;
//   if (isEmpty(obj?.visibilities)) return true;
//   if (obj?.record) return get(obj?.record, `${obj.id}.visibility`, false);
//   return get(data, 'visibility', false);
// };

const SimpleObjectWrap = ({
  ObjectClass,
  obj,
  dependencies,
  onPress,
}: Props) => {
  const data = useSelector(simpleBindingSelector(obj.id));
  const [visibility, setVisibility] = useState(false);

  const getVisibilityValue = useCallback(
    (record: any): any => {
      if (!record) {
        return undefined;
      }
      if (record[obj.id]) {
        return record[obj.id].visibility;
      }

      for (const nestedKey in record) {
        if (typeof record[nestedKey] === 'object') {
          const nestedValue = getVisibilityValue(record[nestedKey]);
          if (nestedValue !== undefined) {
            return nestedValue;
          }
        }
      }
      return undefined;
    },
    [obj.id]
  );

  useEffect(() => {
    if (isEmpty(obj?.visibilities)) {
      setVisibility(true);
    } else {
      if (obj?.record) {
        const visibilityValue = getVisibilityValue(obj?.record);
        setVisibility(visibilityValue);
      } else {
        setVisibility(get(data, 'visibility', false));
      }
    }
  }, [data, getVisibilityValue, obj]);

  //visibility
  if (JSON.stringify(visibility) === 'false')
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
          zIndex: -1,
        }}
      />
    );

  return (
    <ObjectClass
      {...obj}
      data={data}
      dependencies={dependencies}
      onPress={onPress}
    />
  );
};

export default SimpleObjectWrap;
