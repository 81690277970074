import useDependencies from '@common/hooks/useDependencies';
import React, { FC } from 'react';

const DependenciesConnector = (BindingComponent: FC<any>) => {
  return (props: any) => {
    const { obj } = props;
    const { dependencies } = useDependencies({
      obj,
      currentListIds: obj?.currentListIds,
    });
    return <BindingComponent {...props} dependencies={dependencies} />;
  };
};

export default DependenciesConnector;
