import { AxiosResponse } from 'axios';
import { Alert, Platform } from 'react-native';
import api from '@common/configs/api';
import { updateLoggedUserSuccess } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { get, find } from 'lodash';

export const stripePaymentSecurityActionApi = async (
  data:
    | {
        type: 'create-payment';
        secretKey?: string;
        paymentMethod: string;
        email: string;
        stripeAccount: string;
        amount: string | number;
        description: string;
        currency: string;
        customer?: string;
      }
    | {
        type: 'get-card-info';
        secretKey?: string;
        paymentMethod: string;
      }
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await api({
      method: 'post',
      url: '/stripe-payment-security',
      data,
    });
    return response.data;
  } catch (e: any) {
    if (e.response.data) {
      throw e.response.data;
    }
  }
};

export type handleBuyIapType = {
  appType: string;
  purchase: any;
  appId: string;
  appVersion: string;
  userAppId: string;
  coinId: string;
  apple_shared_secret?: string;
};
export const handleBuyIap = async (data: handleBuyIapType) => {
  // console.log('===handleBuyIap', data);
  const response: AxiosResponse<any> = await api({
    method: 'post',
    url: '/in-app-purchase',
    data,
  });
  return response.data;
};

export const showAlert = (message?: string) => {
  if (Platform.OS === 'web') {
    alert(message);
  } else {
    Alert.alert('', message, [
      { text: 'OK', onPress: () => console.log('OK Pressed') },
    ]);
  }
};

type customerChatParam = {
  orderId: string;
  userId: string;
  appId: string;
  message: Record<string, any>;
};
export const customerChatAction = async (data: customerChatParam) => {
  await api({
    method: 'post',
    url: `/app-order/${data.orderId}/conversation`,
    data: {
      userId: data.userId,
      appId: data.appId,
      content: data.message,
    },
  });
};

export const updateRecurringLoggedInUserDispatch = (
  data: Record<string, any>
) => {
  const dispatch = store.dispatch;
  const { recurring } = data || {};

  dispatch(
    updateLoggedUserSuccess({
      user: { recurring },
    })
  );
};

export const handleError = (
  error: any,
  messages: any,
  defaultMessage?: string
) => {
  const state = store.getState();

  const databaseUuid = get(state, 'appInfo.app.metadata.auth.tableId');

  const currentCollection = find(state.database.collections, { databaseUuid });

  const { errors = [], message } = error;
  if (errors.length)
    return (
      errors
        .map((e: any) => {
          const { path, message, validatorKey } = e;

          const uniqueError =
            validatorKey === 'not_unique' &&
            messages[
              'database.content.header.upload.field.unique'.replace(
                'field',
                path
              )
            ];

          if (uniqueError) return uniqueError;

          const fieldError = currentCollection?.fields?.find(
            (field) => field.fid === path
          );

          return message.replace(
            new RegExp(fieldError?.fid ?? '', 'g'),
            fieldError?.name || ''
          );
        })
        .join(', ') || defaultMessage
    );

  return messages[message] || message || defaultMessage;
};
