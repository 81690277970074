import {
  dimensionSelector,
  pullToRefreshSelector,
} from '@common/redux/selectors/page';
import { useLocation } from '@common/routes/hooks';
import { ElementType, IPage } from '@common/types';
import { getPaddingBottomScreen } from '@common/utils/screen';
import { isEmpty, isNil } from 'lodash';
import { default as queryString, ParsedQuery } from 'query-string';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  RefreshControl,
  ScrollView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import ObjectRender from '../ObjectRender';
import { setPullToRefresh } from '@common/redux/slice/page';

type Props = {
  arrNormal: ElementType[];
  screen: IPage;
};

function NormalComponent({ arrNormal, screen }: Props): JSX.Element {
  const dispatch = useDispatch();

  const ref: any = useRef();

  const { search } = useLocation();

  const refreshing = useSelector(pullToRefreshSelector);

  const onRefresh = React.useCallback(() => {
    dispatch(setPullToRefresh(true));
    const timer = setTimeout(() => {
      dispatch(setPullToRefresh(false));
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const pathname = qs.parse(search);

  const dimension = useSelector(dimensionSelector);

  const pullToRefresh = useMemo(
    () => screen?.attributes?.pullToRefresh || false,
    [screen]
  );

  const originY = dimension.height / screen.height;

  let paddingBottom = getPaddingBottomScreen(screen, originY);

  const { hiddenStatusBar }: ParsedQuery<any> = useMemo(
    () => queryString.parse(search),
    [search]
  );

  const isShowStatusBar =
    !screen.isModal &&
    !(Platform.OS === 'android' || Platform.OS === 'ios') &&
    !hiddenStatusBar &&
    screen?.attributes?.statusBarStyle !== 'hidden';

  useLayoutEffect(() => {
    const { targetModal } = pathname;
    if (isNil(targetModal) || isEmpty(targetModal)) {
      ref?.current?.scrollTo({ y: 0, x: 0 });
    }
  }, [screen, ref]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'android' ? undefined : 'padding'}
      style={{ flex: 1 }}
      // keyboardVerticalOffset={50}
    >
      <ScrollView
        bounces={pullToRefresh}
        contentInsetAdjustmentBehavior="automatic"
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled
        ref={ref}
        // style={{ flex: 1, paddingBottom: paddingBottom }}
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: paddingBottom,
        }}
        {...(Platform.OS === 'android' && { overScrollMode: 'never' })}
        {...(pullToRefresh && {
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              progressViewOffset={35}
            />
          ),
        })}
      >
        {arrNormal.length > 0 && (
          <ObjectRender
            arrComp={arrNormal}
            isScreen={true}
            isShowStatusBar={isShowStatusBar}
            screen={screen}
          />
        )}
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

export default NormalComponent;
