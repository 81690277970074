import { Selector, dataBindingType } from '@common/constants/shared';
import { isEmpty } from 'lodash';

/**
 * Recursively extracts source data bindings from a nested object.
 * @param obj - The input object to traverse.
 * @returns A new object containing source data bindings.
 */
export const getSourceDataBinding = (
  obj: Record<string, any>
): Record<string, any> => {
  if (isEmpty(obj)) return {};
  let result: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (dataBindingType.includes(key) && value?.type === 'binding') {
      result = value;
    } else if (typeof value === 'object') {
      result = { ...result, ...getSourceDataBinding(value) };
    }
  }
  return result;
};

/** Reduce values with key in the ids props. */
export const reduceValuesWithIds = (ids: string[]) => (values: any) => {
  if (!ids.length) return;
  return ids.reduce((preValue: any, current: string) => {
    const currentValue = values[current];
    return {
      ...preValue,
      ...(currentValue && {
        [current]: currentValue,
      }),
    };
  }, {});
};

/**
 * Traverse the object.
 * @param obj - The object to traverse.
 * @param callback - The callback with child object.
 * */
export const traverseObject = (obj: any, callback: (child: any) => void) => {
  // Array
  if (Array.isArray(obj)) {
    for (let index = 0; index < obj.length; index++) {
      const child = obj[index];
      traverseObject(child, callback);
    }
    return;
  }
  // None object
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  for (const key in obj) {
    traverseObject(obj[key], callback);
  }

  callback(obj);
};

/**
 * Recursively extracts the ids (routeParams and inputIds) of the dependencies (routeParams and inputIds) from the object.
 * @param obj - The object to traverse.
 * @returns Array routeParams and array inputIds.
 */
export const extractDependenciesFromObject = (
  obj: any
): { routeParams: string[]; inputIds: string[] } => {
  const inputIds: Set<string> = new Set();
  const routeParams: Set<string> = new Set();

  traverseObject(obj, (child) => {
    if (
      child?.selector?.type === Selector.ROUTE_PARAM_SELECTOR &&
      typeof child?.tableId === 'string'
    )
      routeParams.add(child?.tableId);
    if (typeof child?.objectId === 'string') inputIds.add(child?.objectId);
    if (typeof child?.selectObjectId === 'string')
      inputIds.add(child?.selectObjectId);
  });

  return {
    routeParams: Array.from(routeParams),
    inputIds: Array.from(inputIds),
  };
};
