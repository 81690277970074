import React, { Fragment, useState } from 'react';
import { View, Text, Image } from 'react-native';
import AppQrCode from '@common/components/QrCode';

import styles from './style';
import ShareScreenButton from '@common/components/Button/ShareScreenButton/ShareButton';
import { get } from 'lodash';
import Loading from '@common/components/Loading';
import { Button } from 'react-native-paper';
import ModalShareScreen from './ModalShareScreen';
import SharePopup from './ModalShareScreen/SharePopup';
import { IAppInfo } from '@common/types/element';

type Props = {
  onClickCloneApp: () => void;
  appId: string;
  data?: IAppInfo;
  isLoading: boolean;
};

const RenderAppDetail = ({ onClickCloneApp, data, isLoading }: Props) => {
  const [isShowSharePopup, setIsShowSharePopup] = useState(false);

  return (
    <Fragment>
      {isLoading ? (
        <Loading size="large" />
      ) : (
        <View style={styles.appDetail}>
          <View style={styles.appDetailWrapper}>
            <View style={styles.appHeader}>
              <View style={styles.appTitle}>
                <Text style={styles.titleText}>{get(data, 'name', '')}</Text>
              </View>
              <View>
                <ShareScreenButton
                  icon={'sharealt'}
                  mode="outlined"
                  onClick={() => setIsShowSharePopup(true)}
                >
                  SHARE YOUR APP
                </ShareScreenButton>
                {isShowSharePopup && (
                  <ModalShareScreen
                    onClose={() => setIsShowSharePopup(false)}
                    WrappedComponent={SharePopup}
                    appData={data}
                  />
                )}
                {data?.visibility && (
                  <Button
                    mode="contained"
                    style={styles.buttonView}
                    contentStyle={{ paddingVertical: 2, paddingHorizontal: 18 }}
                    labelStyle={{
                      fontSize: 16,
                      lineHeight: 28,
                      letterSpacing: 0.75,
                      fontWeight: '700',
                    }}
                    onPress={onClickCloneApp}
                  >
                    中身を見てみる
                  </Button>
                )}
              </View>
            </View>

            <View style={styles.appDescription}>
              <Text style={styles.appDetailText}>
                {get(data, 'description', '')}
              </Text>
            </View>
            <View style={styles.appBanner}>
              <View style={styles.appLogo}>
                <Image
                  style={styles.appIcon}
                  source={{ uri: get(data, 'icon') }}
                />
              </View>
              <AppQrCode />
            </View>
          </View>
        </View>
      )}
    </Fragment>
  );
};

export default RenderAppDetail;
