import { AxiosResponse } from 'axios';
import api from '@common/configs/api';

type LastBuildResponse = {
  ios?: {
    bundle: string;
    schemeDeepLinkApp: string;
  };
  android?: { bundle: string; schemeDeepLinkApp: string };
};

const useShareSNS = () => {
  const createShareLink = async (
    type: string,
    longUrl: { ios?: string; android?: string }
  ) => {
    try {
      const res: AxiosResponse<{
        shortUrl: string;
        longUrlIos: string;
        longUrlAndroid: string;
      }> = await api({
        method: 'post',
        url: `/share-links`,
        data: {
          longUrl,
          type,
          expiredTime: 1,
        },
      });
      return res.data;
    } catch (error) {
      console.log({ error });
    }
  };
  const getDeviceType = () => {
    if (navigator.vendor.includes('Apple')) {
      return 'ios';
    }
    return 'android';
  };

  const getShareLink = async (shortUrl: string) => {
    try {
      const res: AxiosResponse<any> = await api({
        method: 'get',
        url: `/share-links/${shortUrl}?platform=${getDeviceType()}`,
      });
      return res.data;
    } catch (error) {
      console.log({ error });
    }
  };

  const getLastBuild = async (appUuid: string) => {
    try {
      const res: AxiosResponse<LastBuildResponse> = await api({
        method: 'get',
        url: `/share-link/last-build?appId=${appUuid}`,
      });
      return res.data;
    } catch (error) {
      console.log({ error });
      return { ios: null, android: null };
    }
  };

  return { createShareLink, getShareLink, getLastBuild };
};

export default useShareSNS;
