import { IAppInfo } from '@common/types/element';
import React, { memo, NamedExoticComponent, useState } from 'react';

type Props<T = any> = {
  onClose: () => void;
  WrappedComponent: NamedExoticComponent<T>;
  useCommunityProps?: any;
  appData?: IAppInfo;
};

const ModalShareScreen: React.FC<Props> = ({
  WrappedComponent,
  onClose,
  useCommunityProps,
  appData,
}) => {
  const [showModal, setShowModal] = useState<boolean>(true);
  return (
    <WrappedComponent
      onClose={() => {
        setShowModal(false);
        setTimeout(() => onClose(), 200);
      }}
      useCommunityProps={useCommunityProps}
      isShow={showModal}
      appData={appData}
    />
  );
};

export default memo(ModalShareScreen);
