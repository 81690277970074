import { IInput } from '@common/types/element';
import { getValueBindingComponent } from '@common/utils/component';
import { replace } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { Platform, TextInput, TouchableOpacity, View } from 'react-native';
import connectorInput from '../ConnectorInput';
import createStyles from './style';

const lineOffset = 1.5;

const CusInput: FC<IInput> = (attributes) => {
  const { changeInput, valueInput, contentAdjustHeight } = attributes;
  const [lineHeight, setLineHeight] = useState(attributes.height);

  const styles = createStyles(attributes);
  const keyBoardType = getKeyBoardType(
    attributes.inputType,
    attributes.isInteger
  );

  const isInt = attributes.isInteger;
  const isTel = attributes.inputType === 'tel';
  const maxLength =
    +getValueBindingComponent(attributes, 'maxLength') ||
    +attributes.maxLength?.parsedFormula ||
    undefined;
  const maxHeightContent =
    +getValueBindingComponent(attributes, 'maxHeightContent') ||
    +attributes.maxHeightContent?.parsedFormula ||
    undefined;

  const isPassword = attributes.inputType === 'password';
  const isEmail = attributes.id === 'email';
  const isName = attributes.id === 'fullName';

  const checkMultiline = () => {
    if ((isPassword && isEmail && isName) || attributes.multiline === false) {
      return false;
    } else {
      return attributes.multiline;
    }
  };

  const sanitizeValue = (value: any) => {
    return ['string', 'number'].includes(typeof value) ? value : '';
  };

  const changeHeightInput = useCallback(
    (target: any) => {
      if (
        maxHeightContent &&
        target.scrollHeight + lineOffset >= maxHeightContent
      )
        return;
      target.style.height = 0;
      target.style.height = `${target.scrollHeight + lineOffset}px`;
    },
    [maxHeightContent]
  );

  const handleChangeHeightInput = (value: any) => {
    setLineHeight(value.nativeEvent.contentSize.height);
  };

  const handleChange = useCallback(
    (value) => {
      if (isInt) {
        if (/^\d+$/.test(value) || value === '') {
          changeInput(convertNumberInt(value));
        }
        return;
      }

      if (isTel) {
        if (/^[0-9+-]+$/.test(value) || value === '') {
          changeInput(convertNumberToTel(value));
        }
        return;
      }

      const floatRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
      const isChangeValue =
        attributes.inputType !== 'number' ||
        (attributes.inputType === 'number' && floatRegex.test(value));
      if (isChangeValue) {
        if (attributes.inputType === 'number') {
          changeInput(value.replace(',', '.'));
        } else {
          changeInput(value);
        }
      }
    },
    [isInt, isTel, attributes.inputType, changeInput]
  );

  const onChangeInput = useCallback(
    (event) => {
      changeHeightInput(event.nativeEvent.target);
      handleChange(event.target.value);
    },
    [handleChange]
  );

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        styles.container,
        ...(contentAdjustHeight
          ? [
              {
                ...(Platform.OS !== 'web' && {
                  minHeight: attributes.height,
                  maxHeight: maxHeightContent,
                }),
              },
            ]
          : []),
      ]}
    >
      <View style={styles.wrapper}>
        <TextInput
          style={[
            styles.text,
            ...(contentAdjustHeight
              ? [
                  {
                    ...(Platform.OS !== 'web' && {
                      height: lineHeight,
                      minHeight: attributes.height,
                      maxHeight: maxHeightContent,
                    }),
                  },
                ]
              : []),
          ]}
          placeholder={formatValue(attributes.placeholder)}
          placeholderTextColor={attributes.placeholderColor}
          keyboardType={keyBoardType}
          multiline={checkMultiline()}
          secureTextEntry={isPassword}
          value={sanitizeValue(valueInput)}
          maxLength={maxLength}
          textAlignVertical={
            checkMultiline() && Platform.OS === 'android' ? 'top' : 'auto'
          }
          {...(!contentAdjustHeight
            ? {
                onChangeText: handleChange,
              }
            : {
                ...(Platform.OS !== 'web'
                  ? {
                      onChangeText: handleChange,
                      onContentSizeChange: handleChangeHeightInput,
                    }
                  : {
                      onChange: onChangeInput,
                    }),
              })}
        />
      </View>
    </TouchableOpacity>
  );
};

const convertNumberToTel = (number: string) => {
  const numberArr = number?.split('-').join('').split('') || [];
  const numberNew: string[] = [];

  numberArr.forEach((i: string, index: number) => {
    if (index < 3) return numberNew.push(i);
    if ((index - 3) % 4 === 0) return numberNew.push('-', i);
    return numberNew.push(i);
  });

  return numberNew.join('');
};

const convertNumberInt = (number: string) => {
  if (!number) return number;
  return number.match(/\d+/g)?.join('');
};

const getKeyBoardType = (inputType: string, isInteger?: boolean) => {
  if (isInteger) {
    if (Platform.OS === 'android') {
      return 'number-pad';
    }
    return 'number-pad';
  }

  switch (inputType) {
    case 'email':
      return 'email-address';

    case 'number':
      return 'decimal-pad';
    case 'tel':
      return 'phone-pad';

    default:
      return 'default';
  }
};

const formatValue = (str: any) =>
  replace(str, /<br ?\/?>/g, '\n')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/<\/?p[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<\s*\/?\s*span\s*.*?>/g, '')
    .replace(/<[^>]*>/g, '\n')
    .replace(/&amp;/g, '&')
    .replace(/\n*$/g, '');

export default connectorInput(CusInput);
