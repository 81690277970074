import { pick } from 'lodash';
import { PixelRatio, Platform, StyleSheet } from 'react-native';
import { checkFont, getShadow } from '../func';

const createStyles = (attributes: any) => {
  const getDecoration = () => {
    // if (!isMobile) return { textDecoration: getTextDecorationLine(attributes) };
    // else {
    return {};
    // }
  };
  const through = 'line-through';
  const line = 'underline';
  const combine = 'underline line-through';
  const check = () => {
    if (
      attributes.textDecorationLineThrough &&
      attributes.textDecorationUnderline
    ) {
      return combine;
    }
    if (attributes.textDecorationLineThrough) {
      return through;
    }
    if (attributes.textDecorationUnderline) {
      return line;
    } else {
      return 'none';
    }
  };
  const checkLineHeight = () => {
    if (
      !attributes.lineHeight ||
      attributes.lineHeight < attributes.fontSize * 1.15
    ) {
      return Math.ceil(attributes.fontSize * 1.15);
    }

    return +attributes.lineHeight;
  };
  const checkColor = () => {
    if (attributes.color === '@text') {
      return 'black';
    } else {
      return attributes.color;
    }
  };
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'width',
        // 'height',
        'zIndex',
        'backgroundColor',
        'borderRadius',
        'maxWidth',
      ]),
      minHeight: attributes.height,
      padding: (attributes?.borderRadius || 0) / 2,
      ...(Platform.OS !== 'android' && getShadow(attributes)),
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
    },
    wrapper: {},
    text: {
      ...pick(attributes, [
        // 'color',
        // 'width',
        // 'height',
        // 'borderRadius',
        'zIndex',
      ]),
      ...getDecoration(),
      textAlign: attributes.textAlignment,
      fontStyle: attributes.fontStyleItalic ? 'italic' : 'normal',
      ...(!attributes.fontStyleItalic && {
        fontFamily: checkFont(attributes.fontFamily, attributes.fontWeightBold),
      }),
      minHeight: attributes?.height,
      fontSize: attributes.fontSize / PixelRatio.getFontScale(),
      fontWeight:
        attributes.fontWeightBold === true &&
        attributes.fontFamily === 'Roboto' &&
        Platform.OS === 'android'
          ? 'normal'
          : attributes.fontWeightBold
          ? 'bold'
          : 'normal',
      textDecorationLine: check(),
      ...(attributes?.isInCustomList
        ? { minHeight: attributes.height }
        : attributes.multiline
        ? null
        : { height: attributes.height }),
      lineHeight: checkLineHeight() / PixelRatio.getFontScale(),
      color: checkColor(),
    },
    icon: {
      position: 'absolute',
      right: 10,
    },
  });
};

export default createStyles;
