import { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { Platform } from 'react-native';
import queryString from 'query-string';
import { defaultURL } from '../../../utils/common.constant';
import { convertTextAttributes } from '../../shared';
import { LIST_ITEM_MODE } from '../useSimpleList';

export const TYPE = {
  AVATAR: 'avatar',
  IMAGE: 'image',
  TOGGLE: 'toggle',
  ICON: 'icon',
};

export type TYPE = typeof TYPE[keyof typeof TYPE];

export type ReceivedProps = {
  attributes: Record<string, any>;
  record: Record<string, any>;
  mode: LIST_ITEM_MODE;
  handlePress?: () => void;
};

const useLeftRightSection = (props: ReceivedProps) => {
  const { attributes, record, mode } = props;

  const search = useMemo(() => queryString.parse(window?.location?.search), []);
  const isCanvas = Platform.OS === 'web' && !search?.target;

  const imageBinding = get(record, `${mode}.imageUrl`);

  const getImage = useCallback((type: TYPE) => {
    if (attributes?.imageUrl === undefined) return;

    switch (attributes.imageUrl?.imageType) {
      case 'internal':
        return get(imageBinding, 'url', null);

      default:
        return get(
          record,
          `${mode}.avatarUrl`,
          imageBinding ||
            convertTextAttributes(
              type == 'image'
                ? attributes?.imageUrl?.imageUrl
                : attributes?.avatarUrl
            )
        );
    }
  }, []);

  const placeholderImage = useCallback(() => {
    if (
      get(attributes, 'imageUrl.binding.options.placeholderImageEnabled') &&
      get(attributes, 'imageUrl.imageType') !== 'uploaded'
    ) {
      return get(attributes, 'imageUrl.binding.options.placeholderImage');
    } else {
      return defaultURL;
    }
  }, []);

  return {
    ...props,
    placeholderImage,
    getImage,
    isCanvas,
  };
};

export type Props = ReturnType<typeof useLeftRightSection>;

export default useLeftRightSection;
