import { useMemo } from 'react';
import {
  getCurrentRecord,
  realtimeDatabaseSelector,
} from '@common/redux/selectors/database';
import { useSelector } from 'react-redux';
import { appInfoSelector, locationSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import {
  getDefaultInputsWithIds,
  getFormInputsWithIds,
  getValueInputsWithIds,
} from '@common/redux/selectors/formInputs';
import { localeSelector } from '@common/redux/selectors/language';
import { get, isEmpty, isEqual } from 'lodash';
import { extractDependenciesFromObject } from '@common/utils/binding';
import { getListMultiSelectSelector } from '@common/redux/selectors/multiSelect';
import { showQrCodeValueSelector } from '@common/redux/selectors/page';
import { eachRecursiveTable } from '@common/utils/component';

/**
 * Compute the dependencies of the object.
 * @param props.obj The data of the object computed. Make sure it doesn't change.
 * @param props.currentListIds The current list ids of the object.
 * */
const useDependencies = (props: {
  obj: any;
  currentListIds?: Record<string, any>;
}) => {
  const { obj = {}, currentListIds } = props;
  const screen = obj;
  const appInfo = useSelector(appInfoSelector);
  const loggedUser = useSelector(profileSelector);
  const currentLocation = useSelector(locationSelector);
  const locale = useSelector(localeSelector);
  const databases = useSelector(realtimeDatabaseSelector);

  /** The ids of the input and the route param which the object depends on. */
  const { routeParams, inputIds } = useMemo(
    () => extractDependenciesFromObject(obj ?? {}),
    [obj]
  );

  /** Get the values of the current record current object is depending on.*/
  const currentRecord = useSelector(getCurrentRecord(routeParams), isEqual);

  /** Get the values of the input components current object is depending on.*/
  const valueInputs = useSelector(getValueInputsWithIds(inputIds), isEqual);

  /** Get the values of the form input components current object is depending on. */
  const valueFormInputs = useSelector(getFormInputsWithIds(inputIds), isEqual);

  /** Get the values of the input components (in case they have default values) current object is depending on. */
  const defaultValues = useSelector(getDefaultInputsWithIds(inputIds), isEqual);

  /** Get the list select option of components current object is depending on. */
  const listSelectOptions = useSelector(getListMultiSelectSelector(), isEqual);

  /** Get the value of the action QRCode Scanner */
  const qrCode = useSelector(showQrCodeValueSelector, isEqual);

  const tableRefresh = useMemo(() => {
    const pullToRefresh = get(screen, 'attributes.pullToRefresh', false);
    if (!pullToRefresh) return [];

    const tables = eachRecursiveTable(screen);
    return tables.reduce((preValue: any, current: string) => {
      return { ...preValue, [current]: databases[current] };
    }, {});
  }, [databases, screen]);

  /** The dependencies of the object. */
  const dependencies = useMemo(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      appInfo,
      timeZone,
      loggedUser: loggedUser?.id,
      locale,
      ...(!isEmpty(currentListIds) && {
        currentListIds,
      }),
      ...(!isEmpty(valueInputs) && { valueInputs }),
      ...(!isEmpty(valueFormInputs) && { valueFormInputs }),
      ...(!isEmpty(defaultValues) && { defaultValues }),
      ...(!isEmpty(currentRecord) && { routeParam: currentRecord }),
      ...(!isEmpty(currentLocation) && { currentLocation }),
      ...(!isEmpty(listSelectOptions) && { listSelectOptions }),
      ...(!isEmpty(qrCode) && { scannerId: qrCode }),
      table: tableRefresh,
    };
  }, [
    appInfo,
    valueInputs,
    loggedUser?.id,
    locale,
    currentListIds,
    valueFormInputs,
    defaultValues,
    currentRecord,
    currentLocation,
    listSelectOptions,
    qrCode,
    tableRefresh,
  ]);

  return { dependencies };
};

export default useDependencies;
