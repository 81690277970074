import { ComponentType, Field, IForm } from '@common/types';
import React, { FC, Fragment } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import CusInput from '@common/components/Input';
import CusLabel from '@common/components/Label';
import CusPassword from '@common/components/Password';
import CusSelect from '@common/components/Select';
import CusDatePicker from '@common/components/DatePicker';
import CusCheckbox from '@common/components/Checkbox';
import CusImageUpload from '@common/components/ImageUpload';
import CusFileUpload from '@common/components/FileUpload';
import CusWebView from '@common/components/WebView';
import { dataTypes } from '@common/constants';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import { uuidv4 } from '@common/utils/record';

type Props = {
  object: IForm;
  field: Field;
  active: Boolean;
  hasError: any;
};

const FormField = (props: Props) => {
  let { field, object, hasError } = props;

  const getLabelProps = () => {
    const { fieldStyles } = object || {};
    const labelStyles = (fieldStyles && fieldStyles.labels) || {};
    let { fontFamily } = labelStyles;

    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    // fontFamily = fontFamily || normalizeFontFamily('@body', branding);
    const id = uuidv4();

    return {
      id,
      type: ComponentType.label,
      //   layout: { flex: 1 },
      fontSize: 14,
      fontFamily,
      color: '#000',
      text: field.label,
      ...labelStyles,
      multiline: true,
      maxWidth: object.width,
      height: 27,
      lineHeight: 27,
    };
  };

  const getInputStyles = () => {
    const { fieldStyles } = object || {};
    // let { fontFamily } = fieldStyles;

    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    // fontFamily = fontFamily || normalizeFontFamily('@body', branding);

    return {
      ...(fieldStyles && fieldStyles.inputs),
      color: '#092636',
      //   fontFamily,
    };
  };

  const getInputProps = () => {
    let inputStyles = getInputStyles();
    let fontSize = inputStyles.fontSize || 16;
    let padding = inputStyles.padding === undefined ? 10 : inputStyles.padding;
    let numberOfLines =
      field?.type === 'number' ? false : field?.numberOfLines || false;
    let multiline = field.multiline || inputStyles.multiline || numberOfLines;
    let type = ComponentType.input;
    let height = fontSize * 1.3 + 2 * padding;
    let inputType = 'default';

    let id = `${object.id}-${field?.fid || field.fieldId}`;
    //let dataBinding;
    let database;

    if (field.type === dataTypes.IMAGE) {
      type = ComponentType.imageUpload;
      inputStyles.color = inputStyles.accentColor;
      inputStyles.imageUploadType = 'fullWidth';
      let { width } = object;
      // height = Math.round((width * 2) / 3);
      height = 150;
    } else if (field.type === dataTypes.TEXT) {
      if (field.type === dataTypes.EMAIL) inputType = dataTypes.EMAIL;
    } else if (
      field.type === dataTypes.DATE ||
      field.type === dataTypes.DATE_ONLY
    ) {
      // const { datePickerStyle } = field;

      type = ComponentType.datePicker;
      inputStyles.defaultDateFormat = field.type;
    } else if (field.type === dataTypes.FILE) {
      type = ComponentType.fileUpload;
      inputStyles.color = inputStyles.accentColor || '#6200f3';
      height = 42;
    } else if (field.type === dataTypes.NUMBER) {
      inputType = 'number';
    } else if (field.type === dataTypes.PASSWORD) {
      inputType = 'password';
    } else if (
      typeof field.type == 'object' &&
      field.type?.type === 'belongsTo'
    ) {
      type = ComponentType.select;

      if (field.binding) {
        database = {
          ...field.binding,
          bindingType: 'list',
        };
      }
    }

    if (multiline) height = 80;

    const errorColor = inputStyles.errorColor || 'red';

    return {
      id,
      type,
      //   dataBinding,
      field,
      database,
      marginTop: 7,
      borderWidth: 1,
      ...inputStyles,
      fontSize,
      multiline,
      inputType,
      borderColor: hasError ? errorColor : inputStyles.borderColor || '#ddd',
      // borderStyle: borderStyles.SOLID,
      borderRadius: inputStyles.borderRadius || 4,
      placeholder: field.placeholder,
      padding: inputStyles.padding || 10,
      height,
      // maxHeight: height,
      backgroundColor: '#fff',
      // backgroundStyle: backgroundStyles.COLOR,
      screenUuid: object.screenUuid,
      formId: object.id,
      isFormItem: true,
    };
  };

  const getCheckboxProps = () => {
    let { fieldStyles, submitButton } = object;

    fieldStyles = fieldStyles || { inputs: {} };
    submitButton = submitButton || {};

    let borderColor = fieldStyles.inputs.borderColor || '#ccc';
    let backgroundColor = submitButton.backgroundColor || '#6200ee';

    return {
      id: `${object.id}-${field?.fid || field.fieldId}`,
      type: ComponentType.checkbox,
      activeColor: backgroundColor,
      inactiveColor: borderColor,
      marginRight: 10,
    };
  };

  const renderCheckbox = () => {
    const mappingLabel: { [key: string]: any } = {
      [ComponentType.label]: CusLabel,
    };

    const mappingCheckbox: { [key: string]: any } = {
      [ComponentType.checkbox]: CusCheckbox,
    };

    const checkboxProps = getCheckboxProps();
    const labelProps = {
      ...getLabelProps(),
      // marginLeft: 5,
    };

    let CheckboxObject = mappingCheckbox[checkboxProps.type] as FC;
    let LabelObject = mappingLabel[labelProps.type] as FC;

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 18,
        }}
      >
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <BindingComponent ObjectClass={CheckboxObject} obj={checkboxProps} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 27,
              marginLeft: 5,
            }}
          >
            <BindingComponent ObjectClass={LabelObject} obj={labelProps} />
            {field?.required && (
              <View>
                <Text style={styles.startRequired}>*</Text>
              </View>
            )}
          </View>
        </View>
        {hasError ? renderErrorMessage() : null}
      </View>
    );
  };

  const renderErrorMessage = () => {
    const { hasError } = props;
    const { message } = hasError;
    let inputStyles = getInputStyles();

    const errorStyles = {
      color: inputStyles.errorColor,
    };

    if (!hasError) return null;
    if (!message) return null;
    return <Text style={[styles.errorMessage, errorStyles]}>{message}</Text>;
  };

  if (field.type === dataTypes.BOOLEAN) {
    return renderCheckbox();
  }

  //   let formRowStyles = {};
  //   if (field.type === dataTypes.DATE || field.type === dataTypes.DATE_ONLY) {
  //     formRowStyles.zIndex = 100 - index;
  //   }

  const mappingInput: { [key: string]: any } = {
    [ComponentType.input]: CusInput,
    [ComponentType.password]: CusPassword,
    [ComponentType.select]: CusSelect,
    [ComponentType.datePicker]: CusDatePicker,
    [ComponentType.imageUpload]: CusImageUpload,
    [ComponentType.fileUpload]: CusFileUpload,
    [ComponentType.webView]: CusWebView,
  };

  const mappingLabel: { [key: string]: any } = {
    [ComponentType.label]: CusLabel,
  };

  const inputProps = getInputProps();
  const labelProps = getLabelProps();

  let InputObject = mappingInput[inputProps.type] as FC;
  let LabelObject = mappingLabel[labelProps.type] as FC;

  const renderFormField = () => {
    try {
      return (
        <View style={[styles.formRow]}>
          <View style={styles.fieldLabel} nativeID="click-form-field-label">
            <BindingComponent ObjectClass={LabelObject} obj={labelProps} />
            {field?.required && (
              <View>
                <Text style={styles.startRequired}>*</Text>
              </View>
            )}
          </View>
          {/* <InputObject {...inputProps} /> */}
          <BindingComponent ObjectClass={InputObject} obj={inputProps} />

          {hasError ? renderErrorMessage() : null}
        </View>
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  return <Fragment>{renderFormField()}</Fragment>;
};

export default FormField;

const styles = StyleSheet.create({
  formRow: {
    marginBottom: 18,
  },
  // checkboxRow: {
  //   flexDirection: 'row',
  //   alignContent: 'center',
  //   minHeight: 50,
  //   marginBottom: 18,
  // },
  errorMessage: {
    marginTop: 6,
  },
  startRequired: { color: 'red', marginLeft: 3, fontSize: 17 },
  fieldLabel: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
});
