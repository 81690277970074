import { ActionStatus } from '@common/constants/shared';
import { MetadataAction } from '@common/types/action';
import { get } from 'lodash';
import { ActionResponse } from '../excuteAction';

export const updateObject: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  const { messages } = metadata;
  if (action.status === ActionStatus.SUCCESS) {
    const recordUpdated = action.response;

    return {
      status: ActionStatus.SUCCESS,
      message: messages['database_updated_record'],
      response: recordUpdated,
    };
  }

  return {
    status: ActionStatus.FAILED,
    message: get(action, 'error.message'),
  };
};

export const updateMultipleObject: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  const { messages } = metadata;
  if (action.status === ActionStatus.SUCCESS) {
    return {
      status: ActionStatus.SUCCESS,
      message: messages['database_updated_record'],
      recordUpdatedCount: action.response.updatedCount,
    };
  }

  return {
    status: ActionStatus.FAILED,
    message: get(action, 'error.message'),
  };
};
