import React, { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { devices } from '@common/constants/shared';
import { WebView } from 'react-native-webview';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { dimensionSelector } from '@common/redux/selectors/page';
import { ceil, isEmpty, isNil } from 'lodash';

type Props = {
  appId: string;
  device: any;
};

const IFrame = ({ appId, device }: Props) => {
  const iframeRef: any = useRef();

  const calcScale = (parent: any, children: any) => {
    if (isEmpty(parent) || isEmpty(children)) return 1;
    const scaleX = parent.width / children.width;
    const scaleY = parent.height / children.height;
    const scale = Math.min(scaleX, scaleY);
    return ceil(scale, 6);
  };

  useEffect(() => {
    if (!isNil(iframeRef)) {
      const currenFrame = iframeRef.current.frameRef;

      currenFrame.addEventListener('load', () => {
        setInterval(() => {
          const oldTitle = document?.title;
          const newTitle = currenFrame.contentWindow
            ? currenFrame?.contentWindow?.document?.title
            : currenFrame?.contentDocument?.title;
          if (oldTitle !== newTitle) {
            document.title = newTitle;
          }
        }, 500);
      });
    }
  }, [iframeRef]);

  const deviceInfo = devices[device];

  const dimension = useSelector(dimensionSelector);

  const wrapperStyleEl = useMemo(() => {
    const height = (dimension.height * 90) / 100;
    const width = height / deviceInfo.ratio;
    return { width, height };
  }, [deviceInfo, dimension]);

  const scalePage = useMemo(() => {
    const { clientWidth, clientHeight } = deviceInfo;
    const { width, height } = wrapperStyleEl;

    const scale = calcScale(
      { width, height },
      { width: clientWidth, height: clientHeight }
    );

    return scale;
  }, [deviceInfo, wrapperStyleEl]);

  let newUri = `/preview/${appId}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has('locale')) {
    const locale = urlSearchParams.get('locale');
    newUri = `/preview/${appId}?locale=${locale}`;
  }

  return (
    <View
      style={{
        width: 405,
        height: 842, //deviceInfo.height * scalePage,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 55,
        backgroundColor: 'rgb(0, 0, 0)',
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: 10,
          zIndex: 10000,
          backgroundColor: 'rgb(0, 0, 0)',
          justifyContent: 'center',
          borderBottomLeftRadius: 35,
          borderBottomRightRadius: 35,
          width: 230,
          height: 30,
        }}
      />
      <View>
        <WebView
          source={{ uri: newUri }}
          style={{
            width: 375, // (deviceInfo.width - 30) * scalePage,
            height: 812, //(deviceInfo.height - 30) * scalePage,
            borderRadius: 40,
          }}
          ref={iframeRef}
          scalesPageToFit={true}
        />
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: 25 * scalePage,
          zIndex: 10000,
          width: 230,
          height: 5,
          backgroundColor: '#000',
          borderRadius: 20,
        }}
      />
    </View>
  );
};

IFrame.propTypes = {
  appId: PropTypes.string.isRequired,
  device: PropTypes.string,
};

IFrame.defaultProps = {
  width: 414,
  height: 881,
};

export default memo(IFrame);
