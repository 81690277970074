import { TABLE_DELETE } from '@common/constants/shared';
import { ITable } from '@common/types/element';
import 'antd/dist/antd.css';
import { get, map } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import RowComponent from './RowTable';
import SearchComponent from './SearchComponent';
import AntStyles from './style';
import createStyles from './styles';
import useTable from './useTable';
import { Form, Select, Checkbox, Button, Row, Divider, Col } from 'antd';
import { DownFieldsModal } from './database.style';
import { useIntl } from 'react-intl';
import iconv from 'iconv-lite';
import { localeSelector } from '@common/redux/selectors/language';
import { ConfigProvider } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import en_US from 'antd/lib/locale/en_US';

const CusTable: FC<ITable> = (attributes) => {
  const styles = createStyles(attributes);
  const browserLocale: string = useSelector(localeSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);
  const [tableCols, setTableCols] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [allColumns, setAllColumns] = useState<any[]>([]);
  const [selectedEncoding, setSelectedEncoding] = useState('utf-8');
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(
    attributes.pagination.pageSize
  );
  const [filterText, setFilterText] = useState<string>('');

  const { messages } = useIntl();

  const {
    columns,
    pagination: { position },
    onPress,
    attributes: { searchInput, selectable, enableDownload },
    dataBinding: tableDataBinding,
  } = attributes;

  const defaultWidth = attributes.width / columns?.length;
  const getLocale = () => {
    switch (browserLocale) {
      case 'ja':
        return ja_JP;
      case 'en':
        return en_US;
      default:
        return en_US;
    }
  };
  const {
    dataBinding,
    total,
    initializeList,
    visibility,
    refetch,
    refetchAll,
  } = useTable({
    obj: attributes,
    page,
    pageSize: currentPageSize,
    filterText,
  });

  const handleDelete = useCallback(async () => {
    if (!tableDataBinding?.source?.tableId && selectedRows.length <= 0) return;
    setLoading(true);

    try {
      onPress(TABLE_DELETE, {
        ids: selectedRows.map((item) => item?.id),
      }).then((data: any) => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        refetch();
      });
    } catch (error) {
      console.error('Delete operation failed:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedRows]);

  const displayData = useMemo(() => {
    if (total === 0) return [];
    return map(dataBinding, (item: any) => ({
      ...columns.reduce(
        (acc: any, col: any) => ({
          ...acc,
          [col.id]: get(
            item,
            col.type !== 'actionRef'
              ? `columns.${col.id}.value`
              : `columns.${col.id}.value.text`
          ),
        }),
        {}
      ),
      id: item.id,
      key: item.id,
      _meta: item._meta,
    }));
  }, [dataBinding, columns, total]);

  const handleChangePage = useCallback(
    (newPage: number, newPageSize?: number) => {
      setPage(newPage);
      const newSize = newPageSize || currentPageSize;
      setCurrentPageSize(newSize);
      refetch();
    },
    [currentPageSize, refetch]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setFilterText(value);
      setPage(1);
      refetch(value);
    },
    [refetch]
  );

  useEffect(() => {
    const additionalColumns = [
      { id: 'createdAt', title: messages['database.table.column.createdAt'] },
      { id: 'updatedAt', title: messages['database.table.column.updatedAt'] },
    ];
    const allCols = [...columns, ...additionalColumns];
    setAllColumns(allCols);
    setSelectedColumns(allCols.map((col) => col.id));
  }, [columns]);

  useEffect(() => {
    const tableColumns =
      columns && columns.length > 0
        ? map(columns, (item: Record<string, any>) => {
            return {
              title: item.title,
              key: item.id,
              dataIndex: item.id,
              width: item.width || defaultWidth,
              render: (data: any, record: Record<string, any>) => (
                <RowComponent
                  data={data}
                  col={item}
                  record={record}
                  onPress={onPress}
                  setLoading={setLoading}
                />
              ),
            };
          })
        : [];

    setTableCols(tableColumns);
  }, [columns, defaultWidth, onPress]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (selectedRowsKey: React.Key[]) => {
        setSelectedRowKeys(selectedRowsKey);
        const selectedDataBindingRows = dataBinding.filter((row) =>
          selectedRowsKey.includes(row.id)
        );
        setSelectedRows(selectedDataBindingRows);
      },
    }),
    [selectedRowKeys, dataBinding]
  );

  const handleOpenDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedColumns(e.target.checked ? allColumns.map((col) => col.id) : []);
    setCheckAll(e.target.checked);
    setIndeterminate(false);
  };

  useEffect(() => {
    const allChecked = allColumns.length === selectedColumns.length;
    const someChecked =
      selectedColumns.length > 0 && selectedColumns.length < allColumns.length;
    setIndeterminate(someChecked);
    setCheckAll(allChecked);
  }, []);

  const handleDownload = useCallback(
    async (allData: boolean = false) => {
      setLoading(true);

      try {
        let dataToDownload;
        if (allData) {
          const result = await refetchAll();
          dataToDownload = result?.dataBinding || [];
        } else {
          dataToDownload = selectedRows;
        }

        const columnsToDownload = allColumns.filter((col) =>
          selectedColumns.includes(col.id)
        );

        const headers = columnsToDownload
          .map((col) => `"${col.title}"`)
          .join(',');
        const rows = dataToDownload
          .map((row: any) =>
            columnsToDownload
              .map((col) => {
                let cellData =
                  col.id === 'createdAt' || col.id === 'updatedAt'
                    ? row[col.id]
                    : row[`columns.${col.id}.value`];
                if (cellData === null || cellData === undefined) {
                  cellData = '';
                }
                if (typeof cellData === 'string') {
                  cellData = cellData.replace(/"/g, '""');
                }
                return `"${cellData}"`;
              })
              .join(',')
          )
          .join('\n');
        const csvContent = `${headers}\n${rows}`;

        const encodedContent = iconv.encode(csvContent, selectedEncoding);

        const blob = new Blob([encodedContent], {
          type: `text/csv;charset=${selectedEncoding};`,
        });
        const now = new Date();
        const timestamp =
          now.getFullYear() +
          ('0' + (now.getMonth() + 1)).slice(-2) +
          ('0' + now.getDate()).slice(-2) +
          ('0' + now.getHours()).slice(-2) +
          ('0' + now.getMinutes()).slice(-2);
        const filename = attributes.name || 'noName';

        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `${timestamp}_${filename}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        setIsDownloadModalVisible(false);
      } catch (error) {
        console.error('Download failed:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      dataBinding,
      selectedRows,
      selectedColumns,
      selectedEncoding,
      allColumns,
      attributes.name,
    ]
  );

  if (JSON.stringify(visibility) === 'false')
    return <div style={{ ...styles, zIndex: -100 }} />;

  return (
    <div style={styles}>
      <ConfigProvider locale={getLocale()}>
        <SearchComponent
          disableDeleteButton={selectedRowKeys.length <= 0}
          disableSearch={!searchInput}
          selectable={selectable}
          enableDownload={enableDownload}
          handleSearch={handleSearch}
          handleDelete={handleDelete}
          handleOpenDownloadModal={handleOpenDownloadModal}
          selectedRowKeys={selectedRowKeys}
        />
        <AntStyles
          {...attributes}
          columns={tableCols}
          dataSource={displayData}
          loading={loading || initializeList}
          rowSelection={
            selectable || enableDownload
              ? {
                  type: 'checkbox',
                  ...rowSelection,
                }
              : undefined
          }
          pagination={{
            current: page,
            pageSize: currentPageSize,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: handleChangePage,
            onShowSizeChange: handleChangePage,
            position: [position],
          }}
          scroll={{ y: attributes.height, x: attributes.width }}
        />
        <DownFieldsModal
          visible={isDownloadModalVisible}
          onCancel={() => setIsDownloadModalVisible(false)}
          title={messages['database.popup.download.title']}
          width={400}
          centered
          bodyStyle={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 20em)' }}
          footer={
            <>
              <Form.Item name="encodingForm">
                <Select
                  style={{ width: '100%', textAlign: 'left' }}
                  defaultValue={String(
                    messages['database.popup.download.encoding.default']
                  )}
                  onChange={(value) => setSelectedEncoding(value)}
                >
                  <Select.Option value="utf-8">
                    {messages['database.popup.download.encoding.utf8']}
                  </Select.Option>
                  <Select.Option value="shiftjis">
                    {messages['database.popup.download.encoding.shiftjis']}
                  </Select.Option>
                  <Select.Option value="utf16le">
                    {messages['database.popup.download.encoding.utf16le']}
                  </Select.Option>
                </Select>
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Button type="primary" onClick={() => handleDownload(false)}>
                  {messages['database.popup.download.button.download']}
                </Button>
                <Button type="primary" onClick={() => handleDownload(true)}>
                  {messages['database.popup.download.button.downloadAll']}
                </Button>
              </div>
            </>
          }
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span={22}>
              <strong>{messages['database.popup.download.all']}</strong>
            </Col>
            <Col span={2}>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              />
            </Col>
          </Row>
          <Divider />
          <Form
            labelAlign="left"
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 2 }}
            colon={false}
          >
            {allColumns.map((col, idx) => (
              <Form.Item
                name={col.id}
                label={<span>{col.title}</span>}
                key={idx}
              >
                <Checkbox
                  checked={selectedColumns.includes(col.id)}
                  onChange={(e) => {
                    const newSelectedColumns = e.target.checked
                      ? [...selectedColumns, col.id]
                      : selectedColumns.filter((id) => id !== col.id);
                    setSelectedColumns(newSelectedColumns);
                    const allChecked =
                      newSelectedColumns.length === allColumns.length;
                    const someChecked =
                      newSelectedColumns.length > 0 &&
                      newSelectedColumns.length < allColumns.length;
                    setIndeterminate(someChecked);
                    setCheckAll(allChecked);
                  }}
                />
              </Form.Item>
            ))}
          </Form>
        </DownFieldsModal>
      </ConfigProvider>
    </div>
  );
};

export default CusTable;
