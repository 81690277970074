import { get, pick } from "lodash";
import { StyleSheet } from "react-native";
import _min from "lodash/min";
import _max from "lodash/max";

const createStyles = (attributes: any, Platform: any, isEmptyUrl: boolean) => {
  const hasPlaceholder = get(attributes, 'imageUrl.binding.options', {});

  const getShadow = () => {
    if (!attributes.shadow) return {};
    return {
      shadowColor: attributes.shadow.color,
      shadowRadius: attributes.shadow.size,
      shadowOpacity: attributes.shadow.blur || 1,
      shadowOffset: {
        width: attributes.shadow.x,
        height: attributes.shadow.y,
      },
    };
  };

  const getLayout = () => {
    const width = attributes.width;
    const height = attributes.height;
    if (width && height) {
      if (
        height / 2 === attributes.borderRadius ||
        width / 2 === attributes.borderRadius
      )
        return {
          borderRadius: _max([width, height]) / 2,
          width: _max([width, height]),
          height: _max([width, height]),
        };
    }
    return { borderRadius: attributes.borderRadius, width, height };
  };

  const { borderRadius, width, height } = getLayout();

  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        // 'borderStyle',
        'zIndex',
      ]),
      borderRadius: borderRadius,
      height,
      width,
      ...getShadow(),
      zIndex: attributes.zIndex,
      ...(isEmptyUrl && !hasPlaceholder?.placeholderImageEnabled
        ? { borderWidth: 0 }
        : attributes.borderStyle !== 'none'
        ? {
            ...pick(attributes, ['borderWidth', 'borderColor']),
            borderStyle: attributes.borderStyle,
            borderRadius: attributes.borderRadius === 0 ? 1 : borderRadius,
          }
        : { borderWidth: 0 }),
    },
    wrapper: {},
    img: {
      ...pick(attributes, ['opacity']),
      borderRadius: borderRadius,
      resizeMode: attributes.imageResize,
      width: '100%',
      height: '100%',
    },
  });
};

export default createStyles;
