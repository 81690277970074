import { get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import { Platform } from 'react-native';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import { useMemo } from 'react';

type Item = { id: string; value: string };
export const SELECT_LIST = 'SelectList';
export const useSelectList = (props: any) => {
  const {
    onPress,
    changeInput,
    valueInputs,
    dataBinding = [],
    attributes,
  } = props;
  const { items, useMultipleSelection, selectDatabase } = attributes;
  const isUseDatabase = !!selectDatabase;

  const staticList: Item[] = useMemo(() => {
    return get(attributes, 'itemsSeparate', '')
      .split('\n')
      .map((item: string, index: number) => ({
        id: index.toString(),
        value: item.trim(),
      }))
      .filter((item: Item) => !isNil(item.value) || !isEmpty(item.value));
  }, [attributes]);

  const getInitValues = () => {
    if (Platform.OS !== 'web') {
      // App
      if (dataBinding) {
        return Object.keys(dataBinding)?.length === 0 ? items : dataBinding;
      }
      return items;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return items;
      }
    }
  };

  const listData: Item[] =
    selectDatabase || selectDatabase == undefined
      ? getInitValues().map((item: Item) => {
          const bindingValue = getValueBinding('', item, props);
          const text = isUseDatabase
            ? get(bindingValue, 'content') || get(item, 'content')
            : item.value;
          return {
            id: item.id,
            value: text,
          };
        })
      : staticList;

  const selectedItems = get<Item[]>(valueInputs, 'selectedItems', []);

  const setSelectedItems = (items: Item[]) => {
    changeInput &&
      changeInput({
        componentName: SELECT_LIST,
        selectedItems: items,
        isUseDatabase,
        useMultipleSelection,
      });
  };

  const _getItemsAfterSelected = (item: Item) => {
    const { id } = item;
    // use single selection when use database
    if (!useMultipleSelection) {
      return [item];
    }
    try {
      if (selectedItems.find((e) => e.id === id)) {
        return selectedItems.filter((e) => e.id !== id);
      }
      return [...selectedItems, item];
    } catch (_e) {
      return [item];
    }
  };

  const onItemSelected = (item: Item) => {
    const newItemsSelected = _getItemsAfterSelected(item);
    setSelectedItems(newItemsSelected);
    return newItemsSelected;
  };

  const isItemSelected = (item: Item) => {
    const { id } = item;
    try {
      return selectedItems.find((e) => e.id === id);
    } catch (_e) {
      return false;
    }
  };

  const handlePress = (item: Item) => {
    onItemSelected(item);
    const options = {
      itemListClick: getItemListClick(item),
    };
    onPress && onPress(getActions(props, 'action'), options);
  };

  return {
    selectedItems,
    onItemSelected,
    isItemSelected,
    handlePress,
    isUseDatabase,
    listData,
  };
};
