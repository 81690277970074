import { getValueByKey } from '@common/utils/component';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../store';

export const pagesSelector = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.pages
);

export const pageInfoSelector = (state: RootState) => state.pageInfo;

export const dimensionSelector = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.dimension
);

export const googleMaploaded = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.googleMaploaded
);

export const valueBindingPageSelector = (state: RootState) =>
  state.pageInfo.valueBindingScreen;

export const simpleBindingSelector = (componentId: string) =>
  createSelector(valueBindingPageSelector, (value) =>
    getValueByKey(value, componentId)
  );

export const loadingPageSelector = (state: RootState) =>
  state.pageInfo.loadingPage;

export const showQrCodeSelector = (state: RootState) =>
  state.pageInfo.qrCodeScanner;

export const showQrCodeStatusSelector = (state: RootState) =>
  state.pageInfo.qrCodeScanner.status;
export const showQrCodeValueSelector = (state: RootState) =>
  state.pageInfo.qrCodeScanner.qrCode;

export const bindingLoaderSelector = (state: RootState) =>
  state.pageInfo.bindingLoader;

export const pullToRefreshSelector = (state: RootState) =>
  state.pageInfo.pullToRefresh;
