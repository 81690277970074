import { useEffect, useState } from 'react';
import app from '@common/services/featherIO/feathers.io';
import { useDispatch } from 'react-redux';
import {
  checkAuthError,
  checkAuthSuccess,
  signOut,
} from '@common/redux/slice/auth';

const useSocketConnection = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const authenticate = async () => {
      setLoading(true);
      try {
        const auth_result = await app.reAuthenticate();
        const { userPreview: auth, accessToken } = auth_result;
        
        if (auth) {
          console.log('dispatch checkAuthSuccess');
          dispatch(checkAuthSuccess(Object.assign({}, auth, { accessToken })));
        } else {
          console.log('Check Auth Error: Default Token!');
          dispatch(checkAuthError());
        }
      } catch (error) {
        console.log('Check Auth Error: ', error);
        dispatch(checkAuthError());
      } finally {
        setLoading(false);
      }
    };

    authenticate();
  }, [dispatch]);

  return { loading };
};

export default useSocketConnection;
