import { IQRCode } from '@nocode/types';
import { StyleSheet } from 'react-native';

const createStyles = (props: IQRCode) => {

  const width = props.width > 0 ? props.width : 50;
  const height = props.height > 0 ? props.height : 50;

  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      width: width,
      height: height,
    },
    qrCodeInner: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  });
};

export default createStyles;
