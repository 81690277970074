import { defaultPathComponent } from '@common/constants/shared';
import {
  formValueSelector,
  getValueFields,
  getDefaultValueFields,
} from '@common/redux/selectors/formInputs';
import {
  setDefaultValueInput,
  setFormValue,
  setValueInput,
} from '@common/redux/slice/formInputs';
import {
  convertImage,
  getValueBindingComponent,
  memoriesConditional,
} from '@common/utils/component';
import { get, isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const connectorInput = (InputComponent: any) => {
  return React.memo((props: any) => {
    const formFieldValue = useSelector(formValueSelector);
    const valueInputs = useSelector(getValueFields);
    const defaultValues = useSelector(getDefaultValueFields);
    const dispatch = useDispatch();
    const ref = useRef(0);

    const changeInput = useCallback(
      (value: any) => {
        const valueInput = {
          [props.id]: value,
        };

        if (props?.isFormItem) {
          dispatch(setFormValue(valueInput));
        } else {
          dispatch(setValueInput(valueInput));
        }
      },
      [props.id]
    );

    const setDefaultValue = (value: string) => {
      const valueInput = {
        [props.id]: value,
      };
      dispatch(setDefaultValueInput(valueInput));
    };

    useEffect(() => {
      ref.current += 1;
    });

    const getDefaultValue = useCallback(() => {
      const valueBinding = getValueBindingComponent(
        props,
        defaultPathComponent[props.type]
      );
      switch (props.type) {
        case 'date-picker':
          const date = new Date();
          const isNotBinding =
            props?.defaultDateValue === 'currentTime' ||
            props?.defaultDateValue?.label === 'Current Time';

          return isNotBinding ? date.toISOString() : valueBinding;

        case 'image-upload':
          const image = convertImage(props);
          if (!isEmpty(image)) {
            return typeof image === 'string' ? { url: image } : image;
          } else {
            return valueBinding && typeof valueBinding === 'string'
              ? { url: valueBinding }
              : valueBinding;
          }

        case 'select':
          if (JSON.stringify(props?.defaultValue || null).includes(props?.id))
            return;
          return get(props, 'dataBinding[0].defaultValue');

        default:
          return valueBinding;
      }
    }, [props]);

    useEffect(() => {
      if (
        props?.isFormItem ||
        (props.type === 'date-picker' && ref.current >= 3)
      )
        return;

      const defaultValue = getDefaultValue();

      if (defaultValue && !isEqual(defaultValue, defaultValues[props.id])) {
        setDefaultValue(defaultValue);
      }
    }, [getDefaultValue, defaultValues]);

    const valueInput = useMemo(() => {
      if (props?.isFormItem) {
        return get(formFieldValue, props.id);
      }
      return get(valueInputs, props.id);
    }, [valueInputs, props.id, formFieldValue]);

    const placeholder = get(
      props,
      'defaultValue.binding.options',
      props?.placeholder
    );

    const params = { changeInput, setDefaultValue, valueInput, placeholder };

    return <InputComponent {...props} {...params} />;
  }, memoriesConditional(['dependencies', 'data', 'id', 'dataBinding']));
};

export default connectorInput;
