import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ShareScreen from '../screens/ShareScreen';
import RenderScreen from '../screens/RenderScreen';
import history from './history';
import ShareLink from '@common/screens/ShareLink';

export default function WebRoutes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/share/:shortUrl" component={ShareLink} />
        <Route exact path="/" component={ShareScreen} />
        <Route path="/preview/:appId" component={RenderScreen} />
        <Route path="/:appId" component={ShareScreen} />
      </Switch>
    </Router>
  );
}
