import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
  },
  modalView: {
    width: 620,
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTop: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: '24px',
    paddingVertical: '16px',
    borderBottomWidth: 1,
    borderColor: '#f0f0f0',
    borderStyle: 'solid',
  },
  modalTopTitle: {
    margin: 0,
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 22,
    // wordWrap: 'break-word',
    display: 'flex',
    alignItems: 'center',
  },
  modalBody: {
    padding: 24,
    fontSize: 14,
    lineHeight: 1.5715,
    // wordWrap: 'break-word',
    width: '100%',
  },
  modalBodyContent: {
    position: 'relative',
  },
  optionList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 30,
  },
  optionTouch: {
    width: 80,
    height: 96,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  optionItem: {
    width: 80,
    textAlign: 'center',
    overflow: 'hidden',
    color: 'black',
    // cursor: 'pointer',
  },
  optionIcon: {
    width: '100%',
    // borderRadius: 50,
    height: 80,
    padding: 7,
  },
  optionName: {
    // fontSize: 13,
    fontWeight: '500',
  },
  optionCopyTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 8,
  },
  optionCopyLink: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: '10px',
    paddingHorizontal: '15px',
    backgroundColor: '#efeeee',
    borderWidth: 1,
    borderColor: '#80808047',
    borderRadius: 3,
  },
  optionButtonName: {
    fontSize: 14,
    color: '#066af7',
    textTransform: 'capitalize',
    fontWeight: '400',
    lineHeight: 1.5715,
  },
});

export default styles;
