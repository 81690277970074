import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InAppPurchaseState = {
  isTransactionInProgress: boolean; // Transaction lock
};

const initialState: InAppPurchaseState = {
  isTransactionInProgress: false,
};

const inAppPurchaseSlice = createSlice({
  name: 'inAppParchase',
  initialState: initialState,
  reducers: {
    startPurchaseTransaction: (state: InAppPurchaseState) => {
      if (state.isTransactionInProgress) return state;

      state.isTransactionInProgress = true;
      return state;
    },
    finishPurchaseTransaction: (state: InAppPurchaseState) => {
      state.isTransactionInProgress = false;
      return state;
    },
  },
});

export const { startPurchaseTransaction, finishPurchaseTransaction } =
  inAppPurchaseSlice.actions;
export default inAppPurchaseSlice.reducer;
