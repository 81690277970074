import React from 'react';
import { View, Image as RNImage, Platform } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { isEmpty } from 'lodash';

type Props = {
  imageURL: string;
  placeholder: string;
  attributes: Record<string, any>;
  isCanvas: boolean;
};

const Image = ({ imageURL, placeholder, attributes, isCanvas }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <View
      style={{
        // backgroundColor: '#cccccc',
        width: attributes?.width,
        height: attributes?.height,
        borderRadius: attributes?.borderRadius,
      }}
    >
      {isLoading ? null : (
        <ContentLoader
          speed={3}
          viewBox="0 0 300 300"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <Rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      )}
      <RNImage
        style={{
          ...(!isCanvas && {
            display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
          }),
          width: '100%',
          height: '100%',
          borderRadius: attributes?.borderRadius,
        }}
        source={{
          uri:
            typeof imageURL !== 'string' || isEmpty(imageURL)
              ? placeholder
              : imageURL,
        }}
        onError={() => {
          setIsLoading(false);
        }}
        onLoad={() => {
          setIsLoading(true);
        }}
      />
    </View>
  );
};

export default Image;
