import {
  pullToRefreshSelector,
  simpleBindingSelector,
} from '@common/redux/selectors/page';
import { IRecord, ListObjectProps } from '@common/types';
import { get, pick, debounce, isEqual } from 'lodash';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useSelector } from 'react-redux';
import { fetchRecordList } from '@common/services';
import { appInfoSelector } from '@common/redux/selectors/app';
import { View } from 'react-native';
import useRefreshData from '@common/hooks/useRefreshData';
import { getSourceDataBinding } from '@common/utils/binding';

type DataProp = {
  dataBinding: IRecord[];
  error: any;
  tableSelected: string | any;
  totalPage: number;
  total: number;
};
const PULLING_INTERVAL = 5;

type ListState = {
  data: DataProp;
  page: number;
  initializeList: boolean;
  isLoadMore: boolean;
  isVisible: boolean;
};

enum ListActionType {
  SetState,
  SetData,
  StartLoadMore,
  SetVisible,
  SetPage,
  SetSearch,
}

type SetStateAction = {
  type: ListActionType.SetState;
  payload: Partial<ListState>;
};

type SetDataAction = {
  type: ListActionType.SetData;
  payload: Partial<Omit<ListState, 'data'>> & { data: Partial<DataProp> };
};

type StartLoadMoreAction = {
  type: ListActionType.StartLoadMore;
};
type SetVisibleAction = {
  type: ListActionType.SetVisible;
  payload: boolean;
};
type SetPageAction = {
  type: ListActionType.SetPage;
  payload: number;
};

type ListAction =
  | SetStateAction
  | SetDataAction
  | StartLoadMoreAction
  | SetVisibleAction
  | SetPageAction;

const reducer = (state: ListState, action: ListAction) => {
  switch (action.type) {
    case ListActionType.SetState:
      return { ...state, ...action.payload };
    case ListActionType.SetData:
      return {
        ...state,
        ...action.payload,
        data: { ...state.data, ...action.payload.data },
      };
    case ListActionType.StartLoadMore:
      return { ...state, page: state.page + 1, isLoadMore: true };
    case ListActionType.SetVisible:
      return { ...state, isVisible: action.payload };
    case ListActionType.SetPage:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

const INITIALIZE_STATE: ListState = {
  data: {
    dataBinding: [],
    error: null,
    tableSelected: null,
    total: 0,
    totalPage: 1,
  },
  page: 1,
  initializeList: true,
  isLoadMore: false,
  isVisible: true,
};

const useListObjectWrap = (props: ListObjectProps) => {
  const { obj, dependencies } = props;
  const dataBinding = useMemo(() => getSourceDataBinding(obj), [obj]);
  const [state, dispatch] = useReducer(reducer, INITIALIZE_STATE);
  const { data, page, initializeList, isLoadMore, isVisible } = state;
  const { actionRefresh, databaseRefresh } = useRefreshData({ obj });

  const isListeningForChange = !!get(
    dataBinding,
    'source.options.listenForChanges',
    false
  );

  const dataFormPage = useSelector(simpleBindingSelector(obj.id));
  const refreshing = useSelector(pullToRefreshSelector);
  const appInfo = useSelector(appInfoSelector);

  const onLoadMore = useCallback(() => {
    dispatch({ type: ListActionType.StartLoadMore });
  }, []);

  const fetchData = useCallback(
    debounce(
      async ({
        dependencies,
        controller,
        page,
        obj,
        componentData,
        dataBinding,
      }) => {
        try {
          if (obj.resizeMode) {
            console.log('resize screen');
            return;
          }

          const tableId = get(dataBinding, 'source.tableId');

          if (tableId && appInfo) {
            const recordList = await fetchRecordList({
              obj,
              controller,
              page,
              dataBinding,
              dependencies,
            });

            // check visibility in list, dropdown on api list

            const visible = get(recordList, 'data.visibility', true);

            if (Array.isArray(recordList.data)) {
              const records = recordList.data;
              const payload = {
                data: {
                  tableSelected: tableId,
                  dataBinding:
                    recordList.limit === 0
                      ? []
                      : recordList.limit
                      ? records.slice(0, recordList.limit)
                      : records,
                  total: recordList.total,
                  totalPage: recordList.totalPage ?? 0,
                  error: null,
                },
                isLoadMore: false,
                isVisible: visible,
                initializeList: false,
              };

              dispatch({
                type: ListActionType.SetData,
                payload,
              });
            } else {
              dispatch({
                type: ListActionType.SetState,
                payload: { isVisible: visible, initializeList: false },
              });
            }
          } else if (componentData) {
            dispatch({
              type: ListActionType.SetData,
              payload: {
                data: {
                  dataBinding: [componentData],
                },
                initializeList: false,
              },
            });
          }
        } catch (error: any) {
          if (!controller.signal?.aborted)
            dispatch({
              type: ListActionType.SetState,
              payload: {
                initializeList: false,
              },
            });
        }
      },
      300,
      { leading: true }
    ),
    []
  );

  useEffect(() => {
    const controller = new AbortController();

    const fetchDataProps = {
      dependencies,
      controller,
      page,
      obj,
      componentData: dataFormPage,
      dataBinding,
    };
    fetchData(fetchDataProps);

    let interval: any;
    if (isListeningForChange) {
      interval = setInterval(
        () => fetchData(fetchDataProps),
        PULLING_INTERVAL * 1000
      );
    }
    return () => {
      interval && clearInterval(interval);
      controller.abort();
    };
  }, [
    dependencies,
    obj,
    page,
    appInfo,
    dataFormPage,
    dataBinding,
    fetchData,
    isListeningForChange,
    actionRefresh,
    databaseRefresh,
    refreshing,
  ]);

  // check visibility in on api page
  useEffect(() => {
    const visibility = get(dataFormPage, 'visibility');
    if (visibility !== undefined) {
      dispatch({
        type: ListActionType.SetVisible,
        payload: !!visibility,
      });
    }
  }, [dataFormPage]);

  return {
    ...props,
    data,
    isVisible,
    initializeList,
    isLoadMore,
    page,
    onLoadMore,
    dataBinding,
  };
};

export type Props = ReturnType<typeof useListObjectWrap>;

const ListObjectWrapLayout: FC<Props> = memo((props: Props) => {
  const {
    isVisible,
    obj,
    dependencies,
    data,
    ObjectClass,
    initializeList,
    isLoadMore,
    onPress,
    page,
    onLoadMore,
    dataBinding,
  } = props;

  if (!isVisible)
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
          zIndex: -100,
        }}
      />
    );
  return (
    <ObjectClass
      {...obj}
      {...data}
      initializeList={initializeList}
      isLoadMore={isLoadMore}
      page={page}
      databaseOptions={{ ...dataBinding?.source, id: dataBinding?.id }}
      onLoadMore={onLoadMore}
      dependencies={dependencies}
      onPress={onPress}
    />
  );
}, isEqual);

const ListObjectWrap = (props: ListObjectProps) => (
  <ListObjectWrapLayout {...useListObjectWrap(props)} />
);

export default memo(ListObjectWrap);
