import { simpleBindingSelector } from '@common/redux/selectors/page';
import { fetchComponentBinding } from '@common/services';
import { IRecord } from '@common/types/database';
import { ITable } from '@common/types/element';
import { GetComponentBindingResult } from 'click-types';
import { get, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

const useTable = ({
  obj,
  page = 1,
  pageSize = 10,
  filterText = '',
}: {
  obj: ITable;
  page?: number;
  pageSize?: number;
  filterText?: string;
}) => {
  const [initializeList, setInitializeList] = useState(true);
  const [isVisible, setVisible] = useState(true);
  const [data, setData] = useState({
    total: 1,
    dataBinding: [] as any[],
  });
  const [searchText, setSearchText] = useState('');
  const controllerRef = useRef<AbortController | null>(null);

  const dataFormPage = useSelector(simpleBindingSelector(obj.id));

  const visibility = useMemo(
    () => get(dataFormPage, 'visibility', isVisible),
    [dataFormPage, isVisible]
  );

  const fetchData = useCallback(
    async (fetchAll: boolean = false) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();

      setInitializeList(true);
      try {
        const { dependencies, screenUuid, dataBinding } = obj;
        const tableId = get(dataBinding, 'source.tableId');
        if (!tableId) {
          setData({ total: 0, dataBinding: [] });
          return;
        }

        const { appInfo } = dependencies;
        const { limit = null } = dataBinding?.source?.options || {};

        const componentBindingResult: GetComponentBindingResult =
          await fetchComponentBinding({
            appId: appInfo.id,
            dependencies,
            screenUuid: screenUuid,
            controller: controllerRef.current,
            idComponent: obj.id,
            page: fetchAll ? 1 : page,
            limit: fetchAll ? -1 : pageSize,
            filterText: fetchAll ? '' : searchText || filterText,
          });

        if (
          !isEmpty(componentBindingResult?.data) &&
          Array.isArray(componentBindingResult?.data)
        ) {
          const dataTotal = componentBindingResult.total;
          const total =
            !limit || (limit && limit > dataTotal) ? dataTotal : limit;
          const formatRecord =
            componentBindingResult?.data.length &&
            Array.isArray(componentBindingResult?.data)
              ? componentBindingResult.data.map((record: IRecord) => ({
                  ...record,
                  _meta: {
                    tableId: tableId,
                    datasourceId: obj.id,
                    databindingId: dataBinding?.id,
                  },
                }))
              : [];
          if (fetchAll) {
            return {
              total: componentBindingResult.total,
              dataBinding: formatRecord,
            };
          }
          setData({
            total: total,
            dataBinding: formatRecord,
          });
        } else {
          setData({ total: 0, dataBinding: [] });
        }

        setVisible(get(data, 'data.visibility', true));
      } catch (error) {
        console.error('Error fetching data:', error);
        setData({ total: 0, dataBinding: [] });
      } finally {
        setInitializeList(false);
      }
    },
    [page, pageSize, filterText, searchText, obj]
  );

  const refetch = useCallback((searchKey?: string) => {
    if (searchKey !== undefined) setSearchText(searchKey);
  }, []);

  const refetchAll = useCallback(() => fetchData(true), [fetchData]);

  useEffect(() => {
    fetchData();
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [fetchData]);

  return useMemo(
    () => ({
      initializeList,
      ...data,
      visibility,
      refetch,
      refetchAll,
    }),
    [initializeList, data, visibility, refetch, refetchAll]
  );
};

export default useTable;
