import { reduceValuesWithIds } from '@common/utils/binding';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const currentRecordSelector = (state: RootState) =>
  state.database.currentRecord;

export const web3ConfigSelector = (state: RootState) =>
  state.database.web3Config;

export const realtimeDatabaseSelector = (state: RootState) =>
  state.database.databaseRefresh;

export const actionRefreshSelector = (state: RootState) =>
  state.database.actionRefresh;

export const getCurrentRecord = (ids: string[]) =>
  createSelector(currentRecordSelector, reduceValuesWithIds(ids));
