export default {
  name: 'BookingCalendar',
  displayName: {
    en: 'BookingCalendar',
    ja: 'BookingCalendar',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 400,
  resizeY: true,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What events should be displayed?',
        ja: '表示するデータを選択してください',
      },
      type: 'list',
      disableListOptions: true,
    },
    {
      name: 'eventStarttime',
      displayName: {
        en: 'Event Start',
        ja: 'イベント開始日',
      },
      role: 'listItem',
      reference: 'items',
      type: 'text',
      hideFormatting: true,
    },
    // {
    //   name: 'eventEndtime',
    //   displayName: {
    //     en: 'Event End',
    //     ja: 'イベント終了日',
    //   },
    //   role: 'listItem',
    //   reference: 'items',
    //   type: 'text',
    //   hideFormatting: true,
    // },
    {
      name: 'stockAvailable',
      displayName: {
        en: 'Stock Available',
        ja: 'Stock Available',
      },
      reference: 'items',
      type: 'number',
    },
    {
      name: 'minimumStockAvailable',
      displayName: {
        en: 'Minimum Stock Available',
        ja: 'Minimum Stock Available',
      },
      reference: 'items',
      type: 'number',
    },
    {
      name: 'timeDuration',
      displayName: {
        en: 'Time duration',
        ja: 'Time duration',
      },
      type: 'text',
      default: '30',
      control: {
        type: 'menu',
        options: [
          {
            label: '15 minutes',
            value: '15',
          },
          {
            label: '30 minutes',
            value: '30',
          },
          {
            label: '45 minutes',
            value: '45',
          },
          {
            label: '60 minutes',
            value: '60',
          },
        ],
      },
    },
    {
      name: 'onPress',
      displayName: {
        en: 'Event',
        ja: 'Event',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    {
      name: 'customInitializeTime',
      displayName: {
        en: 'Custom current time',
        ja: 'Custom current time',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'hour',
          type: 'number',
          placeholder: 'Current hour',
          displayName: {
            en: 'Hour (24h format)',
            ja: 'Hour (24h format)',
          },
        },
        {
          name: 'minute',
          type: 'number',
          displayName: {
            en: 'Minute',
            ja: 'Minute',
          },
        },
      ],
    },
    {
      name: 'styles',
      displayName: {
        en: 'Style',
        ja: 'Style',
      },
      props: [
        {
          name: 'availableColor',
          displayName: {
            en: 'Available color',
            ja: 'Available color',
          },
          type: 'color',
        },
        {
          name: 'fewAvailableColor',
          displayName: {
            en: 'Few Available color',
            ja: 'Few Available color',
          },
          type: 'color',
        },
        {
          name: 'notAvailableColor',
          displayName: {
            en: 'Not Available color',
            ja: 'Not Available color',
          },
          type: 'color',
        },
        {
          name: 'saturdayColor',
          displayName: {
            en: 'Saturday color',
            ja: 'Saturday color',
          },
          type: 'color',
        },
        {
          name: 'holidayColor',
          displayName: {
            en: 'Holiday color',
            ja: 'Holiday color',
          },
          type: 'color',
        },
      ],
    },
    // navigation props
    {
      name: 'navigation',
      displayName: {
        en: 'Navigation',
        ja: '選択日のオプション',
      },
      props: [
        {
          name: 'minDate',
          displayName: {
            en: 'Earliest Selectable Date',
            ja: '選択可能な開始日',
          },
          type: 'date',
          default: '2019-01-01',
          placeholder: 'YYYY-MM-DD',
          hideFormatting: true,
        },
        {
          name: 'maxDate',
          displayName: {
            en: 'Latest Selectable Date',
            ja: '選択可能な終了日',
          },
          type: 'date',
          default: '2030-01-01',
          placeholder: 'YYYY-MM-DD',
          hideFormatting: true,
        },
      ],
    },
  ],
};
