import { ActionStatus } from '@common/constants/shared';
import { MetadataAction } from '@common/types/action';
import { get, isEmpty } from 'lodash';
import { ActionResponse } from '../excuteAction';

export const createObject: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  const { messages } = metadata;

  if (action.status === ActionStatus.ERROR) {
    return {
      status: ActionStatus.FAILED,
      message: get(action, 'error.message'),
    };
  }

  const recordCreated = action.response;

  const currentRecordIds = { [recordCreated?.databaseId]: recordCreated?.id };

  return {
    status: ActionStatus.SUCCESS,
    message: messages['database_created_record'],
    recordCreated,
    response: recordCreated,
    ...(!isEmpty(currentRecordIds) && { currentRecordIds }),
  };
};
