import { TOKEN_KEY } from '@common/constants';
import { loadPages } from '@common/redux/slice/page';
import { fetchPages } from '@common/services';
import { IAppInfo, IPage } from '@common/types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { chunk } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatScreens } from '@common/utils';
let pagesJson = require('../json_data/screenObject.json');

type APIResponse = {
  data: any;
  error: string | null;
  isLoading: boolean;
  isUseMap: boolean;
};

const EXPIRIES = 'cacheExpiresOn';
const SCREEN_LOCAL = 'screenLocal';

const convertObject = (pages: IPage[]) => {
  const map: Record<string, any> = {};
  for (let index = 0; index < pages.length; index++) {
    const page = pages[index];
    map[page.screenUuid] = page;
  }
  return map;
};

export const getAllPages = (appInfo: IAppInfo | undefined) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState<APIResponse>({
    data: [],
    error: null,
    isLoading: true,
    isUseMap: false,
  });

  const _fetchPages = useCallback(async () => {
    setResponse((prev) => ({ ...prev, isLoading: true }));
    try {
      const params = {};

      let pages: any[] = [];
      if (appInfo) {
        if (!isEmpty(pagesJson)) {
          pages = Object.keys(pagesJson).map((k: string) => {
            const screen = pagesJson[k];
            return typeof screen.metadata === 'string'
              ? formatScreens({ screen: pagesJson[k] }).single().toView()
              : screen;
          });
        } else {
          const expiresOnLocal = await AsyncStorage.getItem(EXPIRIES);
          const expiresOn = appInfo?.cacheExpiresOn;
          const keys = await AsyncStorage.getAllKeys();

          if (!expiresOn || !expiresOnLocal || expiresOnLocal !== expiresOn) {
            console.log('fetch api ---- ');
            const pageData = await fetchPages(appInfo.id, params);
            pages = pageData.data;

            // remove all token before saving
            const keyNoToken = keys.filter((i) => i != TOKEN_KEY);
            AsyncStorage.multiRemove(keyNoToken);
            // save pages to localstorage
            const chunkPages = chunk(pages, 10);
            const saveData = chunkPages.map((data, index) => [
              SCREEN_LOCAL + index,
              JSON.stringify(data),
            ]);

            try {
              await AsyncStorage.multiSet(saveData);
              await AsyncStorage.setItem(EXPIRIES, pageData.expiresOn);
            } catch (error) {
              await AsyncStorage.multiRemove(
                saveData.map((data) => data[0]).concat(EXPIRIES)
              );
            }
          } else {
            console.log('get local ---- ');
            const listKeys = keys.filter((key) => key.startsWith(SCREEN_LOCAL));
            pages = (await AsyncStorage.multiGet(listKeys))
              .map(([_key, page]) => JSON.parse(page ?? '[]'))
              .flat();
          }
        }
      }

      dispatch(loadPages(convertObject(pages)));

      setResponse((prev) => ({
        ...prev,
        // isUseMap,
        isLoading: false,
        data: pages,
      }));
    } catch (error: any) {
      setResponse((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message || 'get data error',
      }));
    }
  }, [appInfo]);

  useEffect(() => {
    _fetchPages();
  }, [_fetchPages]);

  return response;
};
