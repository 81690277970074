import { get } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import {
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { Button as RNButton } from 'react-native-paper';

import { checkFont, parseText } from '../func';
import { CardSaved } from './CardSave';
import { CARD, STATUS_FINCODE } from './constant';
import { Props } from './hook';
import createStyles from './style';
import { RememberCheckbox } from './RememberCheckbox';

const FincodeForm: React.FC<Props> = (props) => {
  const {
    loading,
    isDisableButton,
    card,
    uriImage,
    attributes,
    checkTypeCard,
    handleChange,
    handleTextSubmit,
    handleSubmit,
    handleRemoveCard,
    cardNameRef,
    cardNumberRef,
    bindingValue,
    onClickCheckbox,
    rememberCard,
    isShowCardSaved,
    isPaymentAction,
  } = props;

  const titleCardNameEnabled = get(
    bindingValue,
    'titleCardName.enabled',
    false
  );
  const titleCardNameText = parseText(
    get(bindingValue, 'titleCardName.text', 'カード名義人')
  );
  const titleCardNumberEnabled = get(
    bindingValue,
    'titleCardName.enabled',
    false
  );
  const titleCardNumberText = parseText(
    get(bindingValue, 'titleCardNumber.text', 'カード番号')
  );
  const submitButtonText = get(bindingValue, 'submitButton.text', '支払う');
  const rememberCheckboxEnabled = get(bindingValue, 'rememberCheckbox.enabled');
  const removeCardText = get(bindingValue, 'submitButton.removeCardText');

  const { width } = attributes;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const {
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
    text: { status, message },
  } = card;

  const styles = createStyles({
    attrs: attributes,
    width,
    disabled: isDisableButton,
    isError: status !== STATUS_FINCODE.SUCCESSFUL,
  });

  const {
    lengths,
    code: { name: nameCvc, size: sizeCvc },
  } = checkTypeCard || {};

  const maxLengthCardNumber = Math.max(...lengths);

  const paymentButton = (
    <View style={styles.btnCard}>
      <RNButton
        style={styles.btn}
        loading={loading}
        disabled={isDisableButton}
        onPress={handleSubmit}
        children={parseText(submitButtonText).toUpperCase()}
        labelStyle={{
          opacity: isDisableButton ? 0.6 : 1,
          fontFamily: checkFont(
            attributes.submitButton.fontFamily,
            undefined,
            target
          ),
          fontSize: attributes.submitButton.fontSize,
          ...(attributes.submitButton.fontSize && {
            lineHeight: attributes.submitButton.fontSize * 1.15,
            paddingVertical: 1.5,
          }),
          color: attributes.submitButton.color,
        }}
      />
    </View>
  );

  if (isShowCardSaved) {
    return (
      <View style={styles.container}>
        <CardSaved
          card={card}
          titleText={titleCardNameText}
          titleEnabled={titleCardNameEnabled}
        />
        <View style={styles.btnCard}>
          {isPaymentAction ? (
            paymentButton
          ) : (
            <RNButton
              style={styles.removeBtn}
              loading={loading}
              onPress={handleRemoveCard}
              children={removeCardText}
              labelStyle={{
                opacity: 1,
                fontFamily: checkFont(
                  attributes.submitButton.fontFamily,
                  undefined,
                  target
                ),
                fontSize: attributes.submitButton.fontSize,
                ...(attributes.submitButton.fontSize && {
                  lineHeight: attributes.submitButton.fontSize * 1.15,
                  paddingVertical: 1.5,
                }),
                color: attributes.submitButton.color,
              }}
            />
          )}
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {titleCardNameEnabled ? (
        <Text style={styles.titleCardName}>{titleCardNameText}</Text>
      ) : (
        <React.Fragment />
      )}
      <TouchableOpacity activeOpacity={1}>
        <View style={styles.inputCardName}>
          <TextInput
            placeholder="TARO YAMADA"
            placeholderTextColor="#ccc"
            style={styles.input}
            value={cardName}
            onChangeText={(text) => handleChange(text, CARD.CARD_NAME)}
            onSubmitEditing={(_) => {
              handleTextSubmit();
            }}
            maxLength={30}
            autoCapitalize="characters"
            autoComplete="name"
            {...(Platform.OS === 'web' && {
              ref: cardNameRef,
            })}
          />
        </View>
      </TouchableOpacity>
      {titleCardNumberEnabled ? (
        <Text style={styles.titleCardNumber}>{titleCardNumberText}</Text>
      ) : (
        <React.Fragment />
      )}
      <View style={styles.inputCardContainer}>
        <View style={styles.inputCardImage}>
          <Image
            style={styles.cardImage}
            source={{ uri: uriImage }}
            resizeMode="cover"
          />
        </View>
        <TouchableOpacity activeOpacity={1} style={styles.inputCardNumber}>
          <View style={styles.inputCardNumber}>
            <TextInput
              placeholder="1234 1234 1234 1234"
              placeholderTextColor="#ccc"
              style={styles.input}
              value={cardNumber}
              {...(Platform.OS === 'web'
                ? { ref: cardNumberRef }
                : {
                    onChangeText: (text) =>
                      handleChange(text, CARD.CARD_NUMBER),
                  })}
              maxLength={maxLengthCardNumber}
              keyboardType="number-pad"
              autoComplete="cc-number"
              ref={cardNumberRef}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardExpiration}>
            <TextInput
              placeholder="MM / YY"
              placeholderTextColor="#ccc"
              style={styles.input}
              value={cardExpiration}
              onChangeText={(text) => handleChange(text, CARD.CARD_EXPIRATION)}
              maxLength={5}
              keyboardType="number-pad"
              autoComplete="cc-exp"
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardCVC}>
            <TextInput
              placeholder={nameCvc}
              placeholderTextColor="#ccc"
              style={styles.input}
              value={cardCvc}
              onChangeText={(text) => handleChange(text, CARD.CARD_CVC)}
              maxLength={sizeCvc}
              keyboardType="number-pad"
              autoComplete="cc-csc"
            />
          </View>
        </TouchableOpacity>
      </View>
      {!!status ? (
        <Text style={styles.text}>{message}</Text>
      ) : (
        <React.Fragment />
      )}
      {rememberCheckboxEnabled ? (
        <RememberCheckbox
          attributes={attributes as any}
          bindingValue={bindingValue}
          fontFamily={attributes.fontFamily}
          onClickCheckbox={onClickCheckbox}
          rememberCard={rememberCard}
        />
      ) : (
        <React.Fragment />
      )}
      <View style={styles.btnCard}>
        <RNButton
          style={styles.btn}
          loading={loading}
          disabled={isDisableButton}
          onPress={handleSubmit}
          children={parseText(submitButtonText).toUpperCase()}
          labelStyle={{
            opacity: isDisableButton ? 0.6 : 1,
            fontFamily: checkFont(
              attributes.submitButton.fontFamily,
              undefined,
              target
            ),
            fontSize: attributes.submitButton.fontSize,
            ...(attributes.submitButton.fontSize && {
              lineHeight: attributes.submitButton.fontSize * 1.15,
              paddingVertical: 1.5,
            }),
            color: attributes.submitButton.color,
          }}
        />
      </View>
    </View>
  );
};

export default FincodeForm;
