import { IBookingCalendar } from '@nocode/types';
import { BindingItemTypeFormatted } from '@nocode/types/bookingCalendar.type';
import { get, pick } from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  FlatList,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { checkStyles, getBorderStyle, getFontWeight } from '../func';
import { isCheckColor } from '../shared';
import { RATIO_TEXT_CONTENT, useBookingCalendar } from './hook';

const dayNameSort = ['日', '月', '火', '水', '木', '金', '土'];

const HeaderTitle: React.FC<{
  onPreviousWeek: VoidFunction;
  onNextWeek: VoidFunction;
  beginDate: Date;
  titleStyle: StyleProp<TextStyle>;
  iconSize: number;
}> = ({ beginDate, onNextWeek, onPreviousWeek, titleStyle, iconSize }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <IconButton
      {...{
        icon: 'chevron-left',
        color: '#fff',
        size: iconSize,
        onPress: onPreviousWeek,
        style: {
          backgroundColor: '#c3c3c3',
        },
      }}
    />
    <Text style={[titleStyle, { marginHorizontal: 10 }]}>
      {moment(beginDate).format('YYYY-MM-DD')} (
      {dayNameSort[moment(beginDate).get('day')]})
    </Text>
    <IconButton
      {...{
        icon: 'chevron-right',
        onPress: onNextWeek,
        size: iconSize,
        color: '#fff',
        style: {
          backgroundColor: '#c3c3c3',
        },
      }}
    />
  </View>
);

const HeaderCalendar: React.FC<{
  beginDate: Date;
  boxStyle: StyleProp<ViewStyle>;
  titleBoxStyle: StyleProp<ViewStyle>;
  getTextStyle: (date: Date) => StyleProp<TextStyle>;
}> = ({ beginDate, boxStyle, titleBoxStyle, getTextStyle }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {Array(8)
        .fill(0)
        .map((_, i) => {
          if (i === 0) {
            return <View key={i} style={[boxStyle, titleBoxStyle]}></View>;
          }
          const date = moment(beginDate).add(i - 1, 'day');
          const dateInMonth = date.get('date');
          const dayLocale = dayNameSort[date.get('day')];
          return (
            <View key={i} style={boxStyle}>
              <Text style={getTextStyle(date.toDate())}>{dayLocale}</Text>
              <Text style={getTextStyle(date.toDate())}>{dateInMonth}</Text>
            </View>
          );
        })}
    </View>
  );
};
const BookingCalendar: React.FC<IBookingCalendar> = (props) => {
  const {
    borderColor,
    borderStyle,
    borderWidth,
    lineHeight,
    styles: customStyles,
  } = props.attributes;
  const boxWidth = props.width / 9;
  const titleBoxWidth = boxWidth * 2;
  const borderStyleFormatted = getBorderStyle(borderStyle);
  const styles = StyleSheet.create({
    container: {
      ...pick(props, ['width', 'height', 'zIndex']),
      ...pick(props.attributes, ['opacity']),
      backgroundColor: isCheckColor(props.attributes.backgroundColor)
        ? props.attributes.backgroundColor
        : undefined,
    },
    text: {
      ...checkStyles(
        pick(props.attributes, ['fontFamily', 'fontSize', 'color'])
      ),
      lineHeight,
    },
    headerCalendarText: {
      fontSize: props.attributes.fontSize * RATIO_TEXT_CONTENT,
      fontWeight: getFontWeight(props.attributes),
      lineHeight: lineHeight * RATIO_TEXT_CONTENT,
    },
    contentCalendarText: {
      fontSize: props.attributes.fontSize * RATIO_TEXT_CONTENT,
      lineHeight: lineHeight * RATIO_TEXT_CONTENT,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
    },
    contentBox: {
      width: boxWidth,
      borderColor,
      borderWidth: borderWidth / 2,
      borderStyle:
        borderStyleFormatted && Platform.OS !== 'web'
          ? 'solid'
          : borderStyleFormatted,
      overflow: 'hidden',
      borderRadius: 1,
    },
    timeDurationBox: {
      width: titleBoxWidth,
      alignItems: 'flex-end',
      display: 'flex',
      paddingEnd: 8,
    },
    headerCalendarDayBox: {
      width: boxWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderColor,
      borderWidth: borderWidth / 2,
      borderTopWidth: borderWidth,
      borderBottomWidth: borderWidth,
    },
    timeDivider: {
      height: 3,
      backgroundColor: borderColor,
      width: props.width,
    },
    holidayTitle: {
      color: get(customStyles, 'holidayColor', '#d0021b'),
    },
    saturdayTitle: {
      color: get(customStyles, 'saturdayColor', '#4a90e2'),
    },
  });
  const {
    durationMapping,
    beginDate,
    onNextWeek,
    onPreviousWeek,
    handlePress,
    initializeTimeIndex,
    listRef,
    holidays,
  } = useBookingCalendar(props);

  const renderRowTime = ({
    timeLabel,
    timeValues,
  }: {
    timeLabel: string;
    timeValues: Record<string, BindingItemTypeFormatted[]>;
  }) => {
    return (
      <View
        key={timeLabel}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={[styles.contentBox, styles.timeDurationBox]}>
          <Text style={[styles.text, styles.headerCalendarText]}>
            {timeLabel}
          </Text>
        </View>
        {Array(7)
          .fill(0)
          .map((_, i) => {
            const date = moment(beginDate).add(i, 'day');
            const items = timeValues[date.format('YYYY-MM-DD')];
            // if we have multiple item, sort by createdAt desc
            const firstItem = items?.sort((a, b) =>
              moment(b.createdAt).diff(a.createdAt)
            )?.[0];
            const isEmpty = !firstItem;
            const isAvailable =
              firstItem &&
              firstItem.stockAvailable > firstItem.minimumStockAvailable;
            const isFewAvailable = firstItem && firstItem.stockAvailable > 0;
            return (
              <TouchableOpacity
                key={i}
                style={[styles.contentBox]}
                onPress={() => {
                  if (firstItem) {
                    handlePress(firstItem.id);
                  }
                }}
              >
                <Text
                  style={[
                    styles.text,
                    styles.contentCalendarText,
                    {
                      color: isAvailable
                        ? customStyles.availableColor
                        : isFewAvailable
                        ? customStyles.fewAvailableColor
                        : customStyles.notAvailableColor,
                    },
                  ]}
                >
                  {isEmpty
                    ? ''
                    : isAvailable
                    ? '○'
                    : isFewAvailable
                    ? '△'
                    : '×'}
                </Text>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };
  return (
    <View style={[styles.container]}>
      {/* Headers */}
      <HeaderTitle
        beginDate={beginDate}
        onNextWeek={onNextWeek}
        onPreviousWeek={onPreviousWeek}
        titleStyle={[styles.text, { fontWeight: 'bold' }]}
        iconSize={props.attributes.fontSize}
        key={'booking-calendar-header-title'}
      />

      <HeaderCalendar
        beginDate={beginDate}
        boxStyle={styles.headerCalendarDayBox}
        titleBoxStyle={styles.timeDurationBox}
        getTextStyle={(date) => {
          const isSaturday = date.getDay() === 6;
          const isSunday = date.getDay() === 0;
          const isHoliday = !!holidays?.[moment(date).format('YYYY-MM-DD')];
          return [
            styles.text,
            styles.headerCalendarText,
            ...(isSaturday ? [styles.saturdayTitle] : []),
            ...(isHoliday || isSunday ? [styles.holidayTitle] : []),
          ];
        }}
        key={'booking-calendar-header-calendar'}
      />

      <FlatList
        key={'booking-calendar-content'}
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        maxToRenderPerBatch={20}
        scrollEnabled={true}
        data={Object.keys(durationMapping)}
        ref={listRef}
        renderItem={({ item, index }) => {
          const timeValues = durationMapping[item];
          if (index - 1 === initializeTimeIndex) {
            return (
              <>
                <View style={styles.timeDivider}></View>
                {renderRowTime({ timeLabel: item, timeValues })}
              </>
            );
          }
          return renderRowTime({ timeLabel: item, timeValues });
        }}
      />
    </View>
  );
};

export default BookingCalendar;
