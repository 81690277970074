import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, ['marginTop', 'marginLeft', 'width']),
      minHeight: attributes.height,
      zIndex: attributes.zIndex,
      backgroundColor: attributes.backgroundColor,
    },
  });
};

export default createStyles;
