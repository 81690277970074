import React from 'react';
import { FC } from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import useShareCustomDomain, { Props, ReceivedProps } from './hook';

const ShareCustomDomainLayout: FC<Props> = (props) => {
  const { url, loading, app } = props;
  return loading ? null : app?.appId ? (
    <View>
      <WebView
        source={{ uri: url }}
        style={{
          width: '100vw',
          height: '100vh',
        }}
        scalesPageToFit={true}
      />
    </View>
  ) : (
    <View>Router Not Found</View>
  );
};

const ShareCustomDomain: FC<ReceivedProps> = (props) => (
  <ShareCustomDomainLayout {...useShareCustomDomain(props)} />
);

export default ShareCustomDomain;
